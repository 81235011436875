import Badge from "antd/lib/badge";
import React, { FC } from "react";
import styles from "./CollapseHeader.module.scss";

interface Props {
  collapseKey: string;
  size: string;
  isCollapseExpanded: boolean;
  assetCount: number;
}

interface Handlers {
  onCollapseExpand: (shouldExpand: boolean, size: string) => void;
}

const CollapseHeader: FC<Props & Handlers> = props => {
  return (
    <div
      className={styles.BuildListCollapseHeader}
      data-cy={`header-${props.collapseKey}`}
    >
      <span
        className={styles.TitleContainer}
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();

          props.onCollapseExpand(!props.isCollapseExpanded, props.size);
        }}
      >
        {props.size}
        <Badge count={props.assetCount} />
      </span>
    </div>
  );
};

export default CollapseHeader;
