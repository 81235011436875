import { Affix, Breadcrumb } from "antd";
import { memo, useCallback, useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { useUser } from "shared/hooks/useUser";
import { IMenu, ISubMenu } from "shared/types/menu";
import MenuOverlay from "./breadcrumbNavigation/MenuOverlay";
import SubmenusOverlay from "./breadcrumbNavigation/SubmenusOverlay";
import { CampaignPlannerBreadcrumb } from "./breadcrumbNavigation/CampaignPlannerBreadcrumb";
import styles from "./BreadcrumbNavigation.module.scss";
interface IProps {
  menus?: IMenu[];
  setCollapsed?: (value: boolean) => void;
}

const isDesignStudioSubMenu = (splitLocationPath: string[]) => {
  return (
    splitLocationPath.length > 3 &&
    splitLocationPath[1] === "design-studio" &&
    (splitLocationPath[3] === "templates" ||
      splitLocationPath[3] === "stamps" ||
      splitLocationPath[3] === "artboards")
  );
};

const isEverythingAdsMenu = (splitLocationPath: string[]) => {
  return (
    splitLocationPath.length > 2 &&
    splitLocationPath[1] === "everything-ads" &&
    splitLocationPath[2] === "ads"
  );
};

const isCampaignPlannerSubMenu = (splitLocationPath: string[]) => {
  return (
    splitLocationPath.length > 2 &&
    splitLocationPath[2] === "campaign-planner" &&
    !!splitLocationPath[3]
  );
};

const filterMenus = (
  menus: IMenu[] | undefined,
  isAdmin: boolean,
  clientName: string,
): IMenu[] => {
  if (!menus) {
    return [];
  }
  const isNotHidden = (item: IMenu | ISubMenu) => !item.hidden;
  const isAllowed = (item: IMenu | ISubMenu) =>
    !item.hidden &&
    !(clientName === "nu" && !isAdmin && item.header !== "Ad Library");

  return menus
    .filter(menu => isNotHidden(menu) && isAllowed(menu))
    .map(menu => ({ ...menu, subMenus: menu.subMenus?.filter(isNotHidden) }));
};

const BreadcrumbNavigation = ({ menus, setCollapsed }: IProps) => {
  const clientName = process.env.REACT_APP_AV2_CLIENT || "internal";
  const location = useLocation();
  const user = useUser();
  const isAdmin = useIsAdmin();

  const filteredMenus: IMenu[] = filterMenus(menus, isAdmin, clientName);

  // /desgin-studio/library/templates
  // current routing structure cannot be changed. editor and editor v2 should have submenus
  const splitLocationPath = location.pathname.split("/");
  const lengthLimit = 4;

  if (isDesignStudioSubMenu(splitLocationPath)) {
    splitLocationPath[2] = "library";
    splitLocationPath.length = lengthLimit;
  }
  const designStudioSubMenuPath = splitLocationPath.join("/");

  const getPathName = useCallback(() => {
    if (isDesignStudioSubMenu(splitLocationPath))
      return designStudioSubMenuPath;
    if (isCampaignPlannerSubMenu(splitLocationPath)) {
      return "/campaign-management/campaign-planner";
    }
    if (isEverythingAdsMenu(splitLocationPath)) return "/everything-ads/ads";
    return location.pathname;
  }, [designStudioSubMenuPath, location.pathname, splitLocationPath]);

  const allSubMenus = filteredMenus?.flatMap(menu => menu.subMenus);

  const filterSelectedSubmenu = useCallback(
    (subMenu: ISubMenu | undefined) =>
      matchPath({ path: `${subMenu?.path}/*` }, getPathName()),
    [getPathName],
  );

  /*
    Sort submenus by path length to find the most specific match. 
    (This is a workaround because some paths (in different sub-menus) are a subset of others. Like /adlibrary and /adlibrary/sessions)
  */
  const selectedSubMenu = [...allSubMenus]
    .sort((a, b) => (b?.path?.length ?? 0) - (a?.path?.length ?? 0))
    .find(filterSelectedSubmenu);

  const selectedMenu = useMemo(
    () =>
      filteredMenus?.find(({ path, subMenus }) => {
        return (
          (path && matchPath({ path: `${path}/*` }, location.pathname)) ||
          subMenus?.some(filterSelectedSubmenu)
        );
      }),
    [filteredMenus, location.pathname, filterSelectedSubmenu],
  );

  const SelectedMenuIcon = selectedMenu?.Icon;

  const urlParams = new URLSearchParams(location.search);
  const feedName =
    urlParams.get("feedName") === "" ? "" : urlParams.get("feedName");
  const isFeedConfigurationPage = location.pathname === "/ad-engine/configure";
  const isCampaignPlanner = location.pathname.includes("campaign-planner");
  return (
    <div
      onClick={() => !!setCollapsed && setCollapsed(true)}
      onMouseEnter={() => !!setCollapsed && setCollapsed(true)}
    >
      <Affix data-cy="breadcrumb-section" style={{ minWidth: "600px" }}>
        <Breadcrumb className={styles.breadcrumbContainer}>
          <Breadcrumb.Item
            className={styles.breadcrumbItem}
            overlay={
              <MenuOverlay
                menus={filteredMenus}
                selectedMenu={selectedMenu}
                user={user}
                setCollapsed={setCollapsed}
              />
            }
          >
            {selectedMenu && SelectedMenuIcon && <SelectedMenuIcon />}
            <span style={{ cursor: "default" }}>
              {selectedMenu && selectedMenu.header}
            </span>
          </Breadcrumb.Item>
          {selectedMenu && selectedSubMenu && (
            <Breadcrumb.Item
              className={styles.breadcrumbItem}
              overlay={
                <SubmenusOverlay
                  selectedMenu={selectedMenu}
                  selectedSubMenu={selectedSubMenu}
                  setCollapsed={setCollapsed}
                />
              }
            >
              <span style={{ cursor: "default" }}>
                {selectedSubMenu.header}
              </span>
            </Breadcrumb.Item>
          )}
          {isFeedConfigurationPage && (
            <Breadcrumb.Item className={styles.breadcrumbItem}>
              <span style={{ cursor: "default" }}>{feedName}</span>
            </Breadcrumb.Item>
          )}
          {isCampaignPlanner && <CampaignPlannerBreadcrumb />}
        </Breadcrumb>
      </Affix>
    </div>
  );
};

export default memo(BreadcrumbNavigation);
