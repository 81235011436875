import {
  adEngineAssetBuilderUrl,
  adEngineFBAdsUrl,
  adEngineFBAutoUrl,
  adEngineFBProductUrl,
  adEngineGoogleMerchantUrl,
  adEnginePinterestProductUrl,
  adEngineSnapchatProductUrl,
  adEngineTiktokProductUrl,
} from "utils/constants";
import { FtpInputAttr, SocialFeedType } from "../types/assetExporter";
import { isFeatureEnabled } from "utils/helpers";

export const videoCompositionEnabled = isFeatureEnabled(
  "ENABLE_VIDEO_COMPOSITION",
  false,
);
export const BROADCAST_CHANNEL_NAME = "edit_template_channel";
export const ROWS_PER_PAGE = 25;
export const MATCHED_COLOR = "#52C41A";
export const WARNING_COLOR = "#FAAD14";
export const UNFORMATTED_TEXT = "Import without formatting";
export const NO_IMPORT_TEXT = "Do not import";
export const MAX_CHAR_DISPLAY = 80;
export const DUPLICATE_ERROR_TEXT = "Selected columns must be unique";
export const REQUIRED_COLS_ERROR_TEXT = "Missing required columns";
export const EMPTY_SMART_COL_WARNING_TEXT =
  "Imported rules cannot be matched to the feed";

export const FB_ADS_CTA_ERROR_TEXT = "CTA is not recognized.";
export const FB_ADS_ADFORMAT_ERROR_TEXT = "Ad Format is not recognized.";
export const ERROR_TEXT_SUFFIX = "Refer to the formatting guidelines";
export const FB_ADS_CTA_WARNING_TEXT = "CTA column not found";
export const DEFINED_ERRORS_NUM = 2;
export const DEFAULT_FTP_PORT = 22;
export const steps = ["Upload", "Map Columns", "Scheduling", "Review"];

export const exportFtpValidationMsgs = {
  required: "Please input FTP ${label}",
  types: {
    number: "${label} is not a valid number!",
  },
};

export const PROCESS_NOTIFICATION_KEY = "process-notification-key";
export const COLUMN_ASSET_BATCHES = "assetBatches";

export const ftpInputs: FtpInputAttr[] = [
  {
    label: "URL",
    value: "url",
    placeholder: "enter url",
    rules: [{ required: true }],
  },
  {
    label: "File path",
    value: "filePath",
    placeholder: "enter file path",
    rules: [{ required: true }],
  },
  {
    label: "Port",
    value: "port",
    placeholder: "enter port",
    rules: [{ required: true, type: "number" }],
  },
  {
    label: "Username",
    value: "username",
    placeholder: "enter username",
    rules: [{ required: true }],
  },
  {
    label: "Password",
    value: "password",
    placeholder: "enter password",
    rules: [{ required: true }],
  },
];

export const FEED_STATUS = "119/120";
export const gsConnectErrorMsg = `Unable to connect to the spreadsheet. Please make sure that the ”General access” is not set to ”Restricted”.`;
export const generalConnectErrorMsg =
  "Unable to connect. Please make sure all fields marked in red are correct and try again.";
export const duplicateHeaderErrorMsg =
  "All columns must have unique names. Please adjust the two or more columns that share the same name before uploading again.";
export const customUploadErrorMsg =
  "Your file couldn't be uploaded. Please check it for errors and try again.";
export const noSuchFileErrorMsg =
  "Please make sure the URL, File Path and Port are correct and try again.";
/**
 * for recommendedColumns
 * name:
 * the name that the social media platform col header is expecting
 *
 * toMatch:
 * these are the incorrect strings we may find in the uploaded feed
 * that we want to match back to the correct name
 *
 * requiredMatching:
 * these are the only acceptable values a cell can contain
 */
export const feedTypes: SocialFeedType[] = [
  {
    name: "Asset Builder",
    type: "assetBuilder",
    recommendedColumns: [
      {
        name: "dealerId",
        toMatch: ["dealerid"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "vehicleType",
        toMatch: ["vehicletype"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "vehicleCondition",
        toMatch: ["vehiclecondition"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "year",
        toMatch: ["year"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "make",
        toMatch: ["make"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "model",
        toMatch: ["model"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "trim",
        toMatch: ["trimlevel"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "driveTrain",
        toMatch: ["drivetrain"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "transmission",
        toMatch: ["transmission"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "cylinders",
        toMatch: ["cylinders"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "mileage",
        toMatch: ["mileage"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "exteriorColor",
        toMatch: ["exteriorcolor"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "imageUrl",
        toMatch: ["imageurl"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "dateInStock",
        toMatch: ["dateinstock"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "feature1",
        toMatch: ["feature1"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "feature2",
        toMatch: ["feature2"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "numberAtThisPrice",
        toMatch: ["numberatthisprice"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "stockNumber",
        toMatch: ["stocknumber"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "vin",
        toMatch: ["vin"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "msrp",
        toMatch: ["msrp"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "invoicePrice",
        toMatch: ["invoiceprice"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "advertisedPrice",
        toMatch: ["advertisedprice"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "optionDescription",
        toMatch: ["optiondescription"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "modelCode",
        toMatch: ["modelcode"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "packagesExtended",
        toMatch: ["packagesextended"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "numberOfPayments",
        toMatch: ["numberofpayments"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "monthlyPayment",
        toMatch: ["monthlypayment"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "totalDueAtSigning",
        toMatch: ["totaldueatsigning"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "totalDueAtSigningInclRebates",
        toMatch: ["totaldueatsigninginclrebates"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseVehicleSalesPrice",
        toMatch: ["leasevehiclesalesprice"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate2",
        toMatch: ["leaserebate1"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate1Name",
        toMatch: ["leaserebate1name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate1Disclosure",
        toMatch: ["leaserebate1disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate2",
        toMatch: ["leaserebate2"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate2Name",
        toMatch: ["leaserebate2name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate2Disclosure",
        toMatch: ["leaserebate2disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate3",
        toMatch: ["leaserebate3"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate3Name",
        toMatch: ["leaserebate3name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate3Disclosure",
        toMatch: ["leaserebate3disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate4",
        toMatch: ["leaserebate4"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate4Name",
        toMatch: ["leaserebate4name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate4Disclosure",
        toMatch: ["leaserebate4disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate5",
        toMatch: ["leaserebate5"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate5Name",
        toMatch: ["leaserebate5name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate5Disclosure",
        toMatch: ["leaserebate5disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate6",
        toMatch: ["leaserebate6"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate6Name",
        toMatch: ["leaserebate6name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseRebate6Disclosure",
        toMatch: ["leaserebate6disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "capCost",
        toMatch: ["capcost"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "capCostReduction",
        toMatch: ["capcostreduction"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "netAdjustedCapitalizedCost",
        toMatch: ["netadjustedcapitalizedcost"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "residualValue",
        toMatch: ["residualvalue"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "totalLeaseCharge",
        toMatch: ["totalleasecharge"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "totalPayments",
        toMatch: ["totalpayments"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "securityDeposit",
        toMatch: ["securitydeposit"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "acquisitionFee",
        toMatch: ["acquisitionfee"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "terminationFee",
        toMatch: ["terminationfee"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "minFICO",
        toMatch: ["minfico"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "milesPerYear",
        toMatch: ["milesperyear"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "centsPerMile",
        toMatch: ["centspermile"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "additionalLeaseDisclosure",
        toMatch: ["additionalleasedisclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "dealerDiscount",
        toMatch: ["dealerdiscount"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "dealerPrice",
        toMatch: ["dealerprice"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate2",
        toMatch: ["purchaserebate1"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate1Name",
        toMatch: ["purchaserebate1name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate1Disclosure",
        toMatch: ["purchaserebate1disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate3",
        toMatch: ["purchaserebate2"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate2Name",
        toMatch: ["purchaserebate2name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate2Disclosure",
        toMatch: ["purchaserebate2disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate4",
        toMatch: ["purchaserebate3"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate3Name",
        toMatch: ["purchaserebate3name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate3Disclosure",
        toMatch: ["purchaserebate3disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate4",
        toMatch: ["purchaserebate4"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate4Name",
        toMatch: ["purchaserebate4name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate4Disclosure",
        toMatch: ["purchaserebate4disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate5",
        toMatch: ["purchaserebate5"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate5Name",
        toMatch: ["purchaserebate5name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate5Disclosure",
        toMatch: ["purchaserebate5disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate6",
        toMatch: ["purchaserebate6"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate6Name",
        toMatch: ["purchaserebate6name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseRebate6Disclosure",
        toMatch: ["purchaserebate6disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "finalPrice",
        toMatch: ["finalprice"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate1",
        toMatch: ["purchaseconditionalrebate1"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate1Name",
        toMatch: ["purchaseconditionalrebate1name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate1Disclosure",
        toMatch: ["purchaseconditionalrebate1disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate2",
        toMatch: ["purchaseconditionalrebate2"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate2Name",
        toMatch: ["purchaseconditionalrebate2name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate2Disclosure",
        toMatch: ["purchaseconditionalrebate2disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate3",
        toMatch: ["purchaseconditionalrebate3"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate3Name",
        toMatch: ["purchaseconditionalrebate3name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate3Disclosure",
        toMatch: ["purchaseconditionalrebate3disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate4",
        toMatch: ["purchaseconditionalrebate4"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate4Name",
        toMatch: ["purchaseconditionalrebate4name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate4Disclosure",
        toMatch: ["purchaseconditionalrebate4disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate5",
        toMatch: ["purchaseconditionalrebate5"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate5Name",
        toMatch: ["purchaseconditionalrebate5name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate5Disclosure",
        toMatch: ["purchaseconditionalrebate5disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate6",
        toMatch: ["purchaseconditionalrebate6"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate6Name",
        toMatch: ["purchaseconditionalrebate6name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseconditionalRebate6Disclosure",
        toMatch: ["purchaseconditionalrebate6disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "priceAfterConditionalRebates",
        toMatch: ["priceafterconditionalrebates"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "savingsOffMSRP",
        toMatch: ["savingsoffmsrp"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "savingsOffMSRPTitle",
        toMatch: ["savingsoffmsrptitle"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "savingsOffMSRPDescription",
        toMatch: ["savingsoffmsrpdescription"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "percentOffMSRP",
        toMatch: ["percentoffmsrp"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "additionalPurchaseDisclosure",
        toMatch: ["additionalpurchasedisclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "aprRate",
        toMatch: ["aprrate"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "aprTerm",
        toMatch: ["aprterm"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "aprPayment",
        toMatch: ["aprpayment"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "aprAmntFinanced",
        toMatch: ["aprtotalfinanced"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "aprMinFICO",
        toMatch: ["aprminfico"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "financeRate",
        toMatch: ["financerate"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "financeTerm",
        toMatch: ["financeterm"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "financePayment",
        toMatch: ["financepayment"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "amntFinanced",
        toMatch: ["totalfinanced"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "financeDownPayment",
        toMatch: ["financedownpayment"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "financeMinFICO",
        toMatch: ["financeminfico"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseNumberOfPayments",
        toMatch: ["zerodownleasenumberofpayments"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseMonthlyPayment",
        toMatch: ["zerodownleasemonthlypayment"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseTotalDueAtSigning",
        toMatch: ["zerodownleasetotaldueatsigning"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeasetotalDueAtSigningInclRebates",
        toMatch: ["zerodownleasetotaldueatsigninginclrebates"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseVehicleSalesPrice",
        toMatch: ["zerodownleasevehiclesalesprice"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate1",
        toMatch: ["zerodownleaserebate1"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate1Name",
        toMatch: ["zerodownleaserebate1name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate1Disclosure",
        toMatch: ["zerodownleaserebate1disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate2",
        toMatch: ["zerodownleaserebate2"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate2Name",
        toMatch: ["zerodownleaserebate2name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate2Disclosure",
        toMatch: ["zerodownleaserebate2disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate3",
        toMatch: ["zerodownleaserebate3"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate3Name",
        toMatch: ["zerodownleaserebate3name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate3Disclosure",
        toMatch: ["zerodownleaserebate3disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate4",
        toMatch: ["zerodownleaserebate4"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate4Name",
        toMatch: ["zerodownleaserebate4name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate4Disclosure",
        toMatch: ["zerodownleaserebate4disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate5",
        toMatch: ["zerodownleaserebate5"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate5Name",
        toMatch: ["zerodownleaserebate5name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate5Disclosure",
        toMatch: ["zerodownleaserebate5disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate6",
        toMatch: ["zerodownleaserebate6"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate6Name",
        toMatch: ["zerodownleaserebate6name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseRebate6Disclosure",
        toMatch: ["zerodownleaserebate6disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseCapCost",
        toMatch: ["zerodownleasecapcost"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseCapCostReduction",
        toMatch: ["zerodownleasecapcostreduction"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseNetAdjustedCapitalizedCost",
        toMatch: ["zerodownleasenetadjustedcapitalizedcost"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseResidualValue",
        toMatch: ["zerodownleaseresidualvalue"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseTotalLeaseCharge",
        toMatch: ["zerodownleasetotalleasecharge"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseTotalPayments",
        toMatch: ["zerodownleasetotalpayments"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseSecurityDeposit",
        toMatch: ["zerodownleasesecuritydeposit"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseAcquisitionFee",
        toMatch: ["zerodownleaseacquisitionfee"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseTerminationFee",
        toMatch: ["zerodownleaseterminationfee"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseMinFICO",
        toMatch: ["zerodownleaseminfico"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseMilesPerYear",
        toMatch: ["zerodownleasemilesperyear"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownLeaseCentsPerMile",
        toMatch: ["zerodownleasecentspermile"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "expirationDate",
        toMatch: ["expirationdate"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "vehicleDisclosure",
        toMatch: ["vehicledisclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseDisclosure",
        toMatch: ["leasedisclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseDisclosure",
        toMatch: ["purchasedisclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "additionalFinanceDisclosure",
        toMatch: ["financedisclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "additionalZeroDownLeaseDisclosure",
        toMatch: ["zerodowndisclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "extraCostDisclosure",
        toMatch: ["extracostdisclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "accountingCost",
        toMatch: ["accountingcost"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "modelId",
        toMatch: ["modelid"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "suggestedDealerContribution",
        toMatch: ["suggesteddealercontribution"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "capCostIncludesAcqusitionFee",
        toMatch: ["capcostincludesacqusitionfee"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "capCostIncludesFirstMonthPayment",
        toMatch: ["capcostincludesfirstmonthpayment"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "DASincludesFirstMonthPayment",
        toMatch: ["dasincludesfirstmonthpayment"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "DASIncludesAcqusitionFee",
        toMatch: ["dasincludesacqusitionfee"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "aprLender",
        toMatch: ["aprlender"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "financeLender",
        toMatch: ["financelender"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "accessoryPrice",
        toMatch: ["accessoryprice"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "packageNames",
        toMatch: ["packagenames"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "packageCodes",
        toMatch: ["packagecodes"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "imageUrls",
        toMatch: ["imageurls"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "optionCodes",
        toMatch: ["optioncodes"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "dmsStatusCode",
        toMatch: ["dmsstatuscode"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownSuggestedDealerContribution",
        toMatch: ["zerodownsuggesteddealercontribution"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "percentageOfMarketAverage",
        toMatch: ["percentageofmarketaverage"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseTransactabilityScore",
        toMatch: ["leasetransactabilityscore"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "loanTransactabilityScore",
        toMatch: ["loantransactabilityscore"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "purchaseTansactabilityScore",
        toMatch: ["purchasetansactabilityscore"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "zeroDownTransactabilityScore",
        toMatch: ["zerodowntransactabilityscore"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "blackBookAverage",
        toMatch: ["blackbookaverage"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "blackBookClean",
        toMatch: ["blackbookclean"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "kbbRetail",
        toMatch: ["kbbretail"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "kbbTradeIn",
        toMatch: ["kbbtradein"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "nadaRetail",
        toMatch: ["nadaretail"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "nadatradeIn",
        toMatch: ["nadatradein"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "lowMilage",
        toMatch: ["lowmilage"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "carfaxOneOwner",
        toMatch: ["carfaxoneowner"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "noAccidentsReported",
        toMatch: ["noaccidentsreported"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "highDemand",
        toMatch: ["highdemand"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "rareFind",
        toMatch: ["rarefind"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "greenVehicle",
        toMatch: ["greenvehicle"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate1",
        toMatch: ["leaseconditionalrebate1"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate1Name",
        toMatch: ["leaseconditionalrebate1name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate1Disclosure",
        toMatch: ["leaseconditionalrebate1disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate2",
        toMatch: ["leaseconditionalrebate2"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate2Name",
        toMatch: ["leaseconditionalrebate2name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate2Disclosure",
        toMatch: ["leaseconditionalrebate2disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate3",
        toMatch: ["leaseconditionalrebate3"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate3Name",
        toMatch: ["leaseconditionalrebate3name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate3Disclosure",
        toMatch: ["leaseconditionalrebate3disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate5",
        toMatch: ["leaseconditionalrebate4"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate4Name",
        toMatch: ["leaseconditionalrebate4name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate4Disclosure",
        toMatch: ["leaseconditionalrebate4disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate5",
        toMatch: ["leaseconditionalrebate5"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate5Name",
        toMatch: ["leaseconditionalrebate5name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate5Disclosure",
        toMatch: ["leaseconditionalrebate5disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate6",
        toMatch: ["leaseconditionalrebate6"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate6Name",
        toMatch: ["leaseconditionalrebate6name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "leaseconditionalRebate6Disclosure",
        toMatch: ["leaseconditionalrebate6disclosure"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "cabStyle",
        toMatch: ["cabstyle"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "drivewayPrice",
        toMatch: ["drivewayprice"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "drivewayExlusionFlag",
        toMatch: ["drivewayexlusionflag"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "kbbLendingValue",
        toMatch: ["kbblendingvalue"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "blackbookWholesale",
        toMatch: ["blackbookwholesale"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "nadaCleanLoan",
        toMatch: ["nadacleanloan"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "drivewayURLs",
        toMatch: ["drivewayurls"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "brandProduct",
        toMatch: ["brand/product"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "module",
        toMatch: ["module"],
        required: false,
        requiredMatching: [],
      },
    ],
    downloadTemplateUrl: adEngineAssetBuilderUrl,
  },
  {
    name: "Facebook Ads",
    type: "facebookAds",
    recommendedColumns: [
      {
        name: "Ad Name",
        toMatch: ["adname", "ad name", "name"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "Ad Format",
        toMatch: ["adformat", "ad format", "format"],
        required: true,
        requiredMatching: [
          "Video",
          "Still",
          "Collection",
          "Carousel",
          "Instant Experience",
          "Automotive Inventory Ads",
          "Dynamic Product Ads",
          "Facebook Travel Ads",
        ],
      },
      {
        name: "Primary Text",
        toMatch: ["primarytext", "primary text", "primary", "text"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "Headline",
        toMatch: ["headline", "head", "line"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "Description",
        toMatch: ["description", "desc"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "CTA",
        toMatch: ["cta"],
        required: false,
        requiredMatching: [
          "Learn More",
          "Shop Now",
          "Get Offer",
          "Contact Us",
          "Get Quote",
          "Book Now",
        ],
      },
      {
        name: "Media Link",
        toMatch: ["medialink", "media link", "media", "link"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "Destination URL",
        toMatch: ["destinationurl", "destination url", "destination"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "Campaign Month",
        toMatch: ["campaignmonth", "campaign month", "campaign", "month"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "Start Date",
        toMatch: ["startdate", "start date", "start"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "End Date",
        toMatch: ["enddate", "end date", "end"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "Tags",
        toMatch: ["tag", "tags"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "UTM",
        toMatch: ["utm"],
        required: false,
        requiredMatching: [],
      },
    ],
    downloadTemplateUrl: adEngineFBAdsUrl,
  },
  {
    name: "Facebook Product Catalog",
    type: "facebookProductCatalog",
    recommendedColumns: [
      {
        name: "id",
        toMatch: ["id"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "title",
        toMatch: ["title"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "description",
        toMatch: ["description", "desc"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "availability",
        toMatch: ["availability"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "condition",
        toMatch: ["condition"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "price",
        toMatch: ["price"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "link",
        toMatch: ["link"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "image_link",
        toMatch: ["image_link", "image link", "image", "img"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "brand",
        toMatch: ["brand"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "google_product_category",
        toMatch: [
          "google_product_category",
          "google product category",
          "google",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "fb_product_category",
        toMatch: [
          "fb_product_category",
          "fb product category",
          "fb",
          "facebook",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "quantity_to_sell_on_facebook",
        toMatch: [
          "quantity_to_sell_on_facebook",
          "quantity to sell on facebook",
          "quantity",
          "sell",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "sale_price",
        toMatch: ["sale_price", "sale price", "price"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "sale_price_effective_date",
        toMatch: [
          "sale_price_effective_date",
          "sale price effective date",
          "effective date",
          "effective",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "item_group_id",
        toMatch: ["item_group_id", "item group id", "item group", "group id"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "gender",
        toMatch: ["gender"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "color",
        toMatch: ["color"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "size",
        toMatch: ["size"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "age_group",
        toMatch: ["age_group", "age group", "age"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "material",
        toMatch: ["material"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "pattern",
        toMatch: ["pattern"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "shipping",
        toMatch: ["shipping"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "shipping_weight",
        toMatch: ["shipping_weight", "shipping_weight"],
        required: false,
        requiredMatching: [],
      },
    ],
    downloadTemplateUrl: adEngineFBProductUrl,
  },
  {
    name: "Facebook Automotive Catalog",
    type: "facebookAutoCatalog",
    recommendedColumns: [
      {
        name: "title",
        toMatch: ["title"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "vehicle_offer_id",
        toMatch: [
          "vehicle_offer_id",
          "vehicle offer id",
          "vehicle",
          "offer id",
          "id",
        ],
        required: true,
        requiredMatching: [],
      },
      {
        name: "offer_description",
        toMatch: [
          "offer_description",
          "offer description",
          "description",
          "desc",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "url",
        toMatch: ["url"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "image[0].url",
        toMatch: ["image[0].url", "image url"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "image[0].tag[0]",
        toMatch: ["image[0].tag[0]", "image tag"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "offer_type",
        toMatch: ["offer_type", "offer type", "type"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "offer_disclaimer",
        toMatch: ["offer_disclaimer", "offer disclaimer", "disclaimer"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "availability",
        toMatch: ["availability"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "price",
        toMatch: ["price"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "custom_number_0",
        toMatch: ["custom_number_0", "custom number 0", "custom number"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "applink.android_app_name",
        toMatch: [
          "applink.android_app_name",
          "applink.android app name",
          "applink android app name",
          "applink android name",
          "android name",
          "applink.android",
          "app name",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "applink.android_package",
        toMatch: [
          "applink.android_package",
          "applink.android package",
          "applink android package",
          "android package",
          "package",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "applink.android_url",
        toMatch: [
          "applink.android_url",
          "applink.android url",
          "applink android url",
          "android url",
          "android url",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "applink.ios_app_name",
        toMatch: [
          "applink.ios_app_name",
          "applink.ios app name",
          "applink ios app name",
          "ios app name",
          "applink.ios",
          "ios name",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "applink.ios_app_store_id",
        toMatch: [
          "applink.ios_app_store_id",
          "applink.ios app store id",
          "applink ios app store id",
          "ios app store id",
          "store id",
          "store id",
          "storeId",
          "store_id",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "applink.ios_url",
        toMatch: [
          "applink.ios_url",
          "applink.ios url",
          "applink ios url",
          "applink url",
          "ios url",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "applink.ipad_app_name",
        toMatch: [
          "applink.ipad_app_name",
          "applink.ipad app name",
          "applink ipad app name",
          "ipad app name",
          "ipad name",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "applink.ipad_app_store_id",
        toMatch: [
          "applink.ipad_app_store_id",
          "applink.ipad app store id",
          "app",
          "store",
          "id",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "applink.ipad_url",
        toMatch: ["applink.ipad_url", "applink.ipad url", "applink ipad url"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "applink.iphone_app_name",
        toMatch: [
          "applink.iphone_app_name",
          "applink.iphone app name",
          "applink iphone app name",
          "iphone app name",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "applink.iphone_app_store_id",
        toMatch: [
          "applink.iphone_app_store_id",
          "applink.iphone app store id",
          "applink iphone app store id",
          "iphone app store id",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "applink.iphone_url",
        toMatch: [
          "applink.iphone_url",
          "applink.iphone url",
          "applink iphone url",
          "iphone url",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "applink.windows_phone_app_id",
        toMatch: [
          "applink.windows_phone_app_id",
          "applink.windows phone app id",
          "applink windows phone app id",
          "windows phone app id",
          "phone app id",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "applink.windows_phone_app_name",
        toMatch: [
          "applink.windows_phone_app_name",
          "applink.windows phone app name",
          "applink windows phone app name",
          "windows phone app name",
          "phone app name",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "applink.windows_phone_url",
        toMatch: [
          "applink.windows_phone_url",
          "applink.windows phone url",
          "applink windows phone url",
          "windows phone url",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "amount_price",
        toMatch: ["amount_price", "amount price", "price"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "amount_percentage",
        toMatch: ["amount_percentage", "amount percentage", "percentage"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "amount_qualifier",
        toMatch: ["amount_qualifier", "amount qualifier", "qualifier"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "term_length",
        toMatch: ["term_length", "term length", "length"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "term_qualifier",
        toMatch: ["term_qualifier", "term qualifier", "qualifier"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "downpayment",
        toMatch: ["downpayment", "down payment", "payment"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "downpayment_qualifier",
        toMatch: ["downpayment_qualifier", "downpayment qualifier"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "cashback",
        toMatch: ["cashback", "cash back", "cash"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "start_time",
        toMatch: ["start_time", "start time", "start"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "end_time",
        toMatch: ["end_time", "end time", "end"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "dma_codes[0]",
        toMatch: ["dma_codes[0]", "dma codes[0]"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "dma_codes[1]",
        toMatch: ["dma_codes[1]", "dma codes[1]"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "make",
        toMatch: ["make"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "model",
        toMatch: ["model"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "trim",
        toMatch: ["trim"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "year",
        toMatch: ["year"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "body_style",
        toMatch: ["body_style", "body style", "body", "style"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "overlay_disclaimer",
        toMatch: ["overlay_disclaimer", "overlay disclaimer", "overlay"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "offer_disclaimer_url",
        toMatch: ["offer_disclaimer_url", "offer disclaimer url"],
        required: false,
        requiredMatching: [],
      },
    ],
    downloadTemplateUrl: adEngineFBAutoUrl,
  },
  {
    name: "TikTok Product Catalog",
    type: "tiktokProductCatalog",
    recommendedColumns: [
      {
        name: "sku_id",
        toMatch: ["sku_id", "sku id", "sku", "id"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "title",
        toMatch: ["title"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "description",
        toMatch: ["description", "desc"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "availability",
        toMatch: ["availability"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "condition",
        toMatch: ["condition"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "price",
        toMatch: ["price"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "link",
        toMatch: ["link"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "image_link",
        toMatch: ["image_link", "image link", "image"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "video_link",
        toMatch: ["video_link", "video link", "video"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "brand",
        toMatch: ["brand"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "additional_image_link",
        toMatch: ["additional_image_link", "additional image link"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "age_group",
        toMatch: ["age_group", "age group", "age", "group"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "color",
        toMatch: ["color"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "gender",
        toMatch: ["gender"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "item_group_id",
        toMatch: ["item_group_id", "item group id", "item group"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "google_product_category",
        toMatch: [
          "google_product_category",
          "google product category",
          "google product",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "material",
        toMatch: ["material"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "pattern",
        toMatch: ["pattern"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "product_type",
        toMatch: ["product_type", "product type", "type"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "sale_price",
        toMatch: ["sale_price", "sale price", "sale", "price"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "sale_price_effective_date",
        toMatch: [
          "sale_price_effective_date",
          "sale price effective date",
          "effective date",
          "sale price date",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "shipping",
        toMatch: ["shipping"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "shipping_weight",
        toMatch: ["shipping_weight", "shipping weight", "weight"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "gtin",
        toMatch: ["gtin"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "mpn",
        toMatch: ["mpn"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "size",
        toMatch: ["size"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "tax",
        toMatch: ["tax"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "ios_url",
        toMatch: ["ios_url", "ios url"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "ios_app_store_id",
        toMatch: [
          "ios_app_store_id",
          "ios app store id",
          "ios store id",
          "app store id",
          "store id",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "ios_app_name",
        toMatch: ["ios_app_name", "ios app name", "ios name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "iPhone_url",
        toMatch: ["iphone_url", "iphone url"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "iPhone_app_store_id",
        toMatch: [
          "iphone_app_store_id",
          "iphone app store id",
          "iphone store id",
          "app store id",
          "store id",
        ],
        required: false,
        requiredMatching: [],
      },
      {
        name: "iPhone_app_name",
        toMatch: ["iphone_app_name", "iphone app name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "iPad_url",
        toMatch: ["ipad_url", "ipad url"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "iPad_app_store_id",
        toMatch: ["ipad_app_store_id", "ipad app store id"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "iPad_app_name",
        toMatch: ["ipad_app_name", "ipad app name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "android_url",
        toMatch: ["android_url", "android url"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "android_package",
        toMatch: ["android_package", "android package"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "android_app_name",
        toMatch: ["android_app_name", "android app name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "custom_label_0",
        toMatch: ["custom_label_0", "custom label 0"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "custom_label_1",
        toMatch: ["custom_label_1", "custom label 1"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "custom_label_2",
        toMatch: ["custom_label_2", "custom label 2"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "custom_label_3",
        toMatch: ["custom_label_3", "custom label 3"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "custom_label_4",
        toMatch: ["custom_label_4", "custom label 4"],
        required: false,
        requiredMatching: [],
      },
    ],
    downloadTemplateUrl: adEngineTiktokProductUrl,
  },
  {
    name: "Snapchat Product Catalog",
    type: "snapchatProductCatalog",
    recommendedColumns: [
      {
        name: "id",
        toMatch: ["id"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "title",
        toMatch: ["title"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "description",
        toMatch: ["description"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "link",
        toMatch: ["link"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "image_link",
        toMatch: ["image_link", "image link"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "availability",
        toMatch: ["availability"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "price",
        toMatch: ["price"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "condition",
        toMatch: ["condition"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "brand",
        toMatch: ["brand"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "age_group",
        toMatch: ["age_group", "age group", "age", "group"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "color",
        toMatch: ["color"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "gender",
        toMatch: ["gender"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "item_group_id",
        toMatch: ["item_group_id", "item group id", "group id"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "google_product_category",
        toMatch: ["google_product_category", "google product category"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "product_type",
        toMatch: ["product_type", "product type", "product", "type"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "adult",
        toMatch: ["adult"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "custom_label_0",
        toMatch: ["custom_label_0", "custom label 0"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "custom_label_1",
        toMatch: ["custom_label_1", "custom label 1"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "custom_label_2",
        toMatch: ["custom_label_2", "custom label 2"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "custom_label_3",
        toMatch: ["custom_label_3", "custom label 3"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "custom_label_4",
        toMatch: ["custom_label_4", "custom label 4"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "mobile_link",
        toMatch: ["mobile_link", "mobile link"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "icon_media_url",
        toMatch: ["icon_media_url", "icon media url", "media url"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "ios_app_name",
        toMatch: ["ios_app_name", "ios app name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "ios_app_store_id",
        toMatch: ["ios_app_store_id", "ios app store id"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "ios_url",
        toMatch: ["ios_url", "ios url"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "android_app_name",
        toMatch: ["android_app_name", "android app name"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "android_package",
        toMatch: ["android_package", "android package"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "android_url",
        toMatch: ["android_url", "android url"],
        required: false,
        requiredMatching: [],
      },
    ],
    downloadTemplateUrl: adEngineSnapchatProductUrl,
  },
  {
    name: "Pinterest Product Catalog",
    type: "pinterestProductCatalog",
    recommendedColumns: [
      {
        name: "id",
        toMatch: ["id"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "item_group_id",
        toMatch: ["item_group_id", "item group id", "group id"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "title",
        toMatch: ["title"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "description",
        toMatch: ["description"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "link",
        toMatch: ["link"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "image_link",
        toMatch: ["image_link", "image link", "image"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "price",
        toMatch: ["price"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "availability",
        toMatch: ["availability"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "condition",
        toMatch: ["condition"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "google_product_category",
        toMatch: ["google_product_category", "google product category"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "product_type",
        toMatch: ["product_type", "product type", "product", "type"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "additional_image_link",
        toMatch: ["additional_image_link", "additional image link"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "sale_price",
        toMatch: ["sale_price", "sale price", "sale", "price"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "brand",
        toMatch: ["brand"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "gender",
        toMatch: ["gender"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "age_group",
        toMatch: ["age_group", "age group", "age"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "size",
        toMatch: ["size"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "size_type",
        toMatch: ["size_type", "size type"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "shipping",
        toMatch: ["shipping"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "custom_label_0",
        toMatch: ["custom_label_0", "custom label 0", "custom", "label"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "adwords_redirect",
        toMatch: [
          "adwords_redirect",
          "adwords redirect",
          "adwords",
          "redirect",
        ],
        required: false,
        requiredMatching: [],
      },
    ],
    downloadTemplateUrl: adEnginePinterestProductUrl,
  },
  {
    name: "Google Merchant Center",
    type: "googleMerchantCenter",
    recommendedColumns: [
      {
        name: "id",
        toMatch: ["id"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "title",
        toMatch: ["title"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "description",
        toMatch: ["description"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "link",
        toMatch: ["link"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "condition",
        toMatch: ["condition"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "price",
        toMatch: ["price"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "availability",
        toMatch: ["availability"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "image",
        toMatch: ["image"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "gtin",
        toMatch: ["gtin"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "mpn",
        toMatch: ["mpn"],
        required: false,
        requiredMatching: [],
      },
      {
        name: "brand",
        toMatch: ["brand"],
        required: true,
        requiredMatching: [],
      },
      {
        name: "google product category",
        toMatch: [
          "google product category",
          "google",
          "product",
          "product category",
          "category",
        ],
        required: false,
        requiredMatching: [],
      },
    ],
    downloadTemplateUrl: adEngineGoogleMerchantUrl,
  },
  {
    name: "Google Performance Max",
    type: "pmax",
    recommendedColumns: [],
    downloadTemplateUrl:
      "https://docs.google.com/spreadsheets/d/1kfjuba7pgBZ7g1Z1Ns3ikb1WBwNzyj2R_x7HdwK0AXI/edit?pli=1#gid=0",
    redirect: true,
  },
  {
    name: "Custom",
    type: "custom",
    recommendedColumns: [],
    downloadTemplateUrl: "",
  },
];
