import { CaretDownOutlined } from "@ant-design/icons";
import { Empty, Input, Tree } from "antd";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { getAdKeyFromDest } from "../../../campaignData.utils";
import { useCurrentSession } from "screens/adLibrary/adLoadV2/hooks/useCurrentSession";
import {
  useGetCheckedKeys,
  useOnCheckKeys,
} from "./campaignTree/useCheckedKeys";
import { useState } from "react";
import { useFilteredTreeData } from "./campaignTree/useFilteredTreeData";

type Props = {
  selectedAdIds: string[];
};

const CampaignTree = ({ selectedAdIds }: Props) => {
  const [searchValue, setSearchValue] = useState("");
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value.toLowerCase());
  };
  const {
    treeData,
    filteredTreeData,
    filteredTreeDataWithDefaults,
    isLoadingCampaigns,
  } = useFilteredTreeData(searchValue);

  const { adDests } = useCurrentSession();
  const adKeys = adDests.map(getAdKeyFromDest);

  const { onCheck } = useOnCheckKeys({ selectedAdIds });
  const { checkedKeys } = useGetCheckedKeys();
  const selectedKeys = checkedKeys.concat(adKeys);

  const { windowInnerHeight } = useWindowSize();

  return (
    <>
      <Input.Search
        style={{ marginBottom: 8 }}
        placeholder="Search"
        value={searchValue}
        onChange={onChange}
        allowClear
      />
      {/* This is a "hack" to expand all tree nodes after the user selects a store */}
      <div key={treeData.length + isLoadingCampaigns.toString()}>
        <Tree
          treeData={filteredTreeDataWithDefaults}
          checkable
          checkedKeys={checkedKeys}
          onCheck={onCheck}
          selectedKeys={selectedKeys}
          switcherIcon={<CaretDownOutlined />}
          showLine
          multiple
          virtual
          height={windowInnerHeight - 300}
          defaultExpandParent
          defaultExpandAll
        />
        {!!treeData.length && !filteredTreeData.length && (
          <Empty description="No results found" />
        )}
      </div>
    </>
  );
};

export default CampaignTree;
