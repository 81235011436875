import { Button, Typography } from "antd";
import moment from "moment";
import { useCallback, useMemo } from "react";

import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";

import useAdReviewPreferences from "./shared/hooks/useAdReviewPreferences";

import styles from "./HeaderToolbar.module.scss";

import {
  FilePdfOutlined,
  GroupOutlined,
  ShareAltOutlined,
  UngroupOutlined,
} from "@ant-design/icons";
import { useCampaignManagementSorter } from "screens/adLibrary/shared/hooks/dataListHooks";
import SingleAccountSelect from "shared/components/SingleAccountSelect";
import SwitchableDatePicker from "shared/components/SwitchableDatePicker";
import { useSelectedStore } from "shared/hooks/userPreferences/useSelectedStore";
import { ISharedAdReview } from "shared/types/adReview";
import { SelectedDates } from "shared/types/campaignManagement";
import { IAccount } from "shared/types/accountManagement";
import { isFeatureEnabled } from "utils/helpers";
import { transformSecondsToMilliseconds } from "utils/helpers.date";

type HeaderToolbarProps = {
  showContent?: boolean;
  displayColumn?: boolean;
  validDateRange?: boolean;
  allowSelected: any;
  selectedAdIds: string[];
  isLoadingPdfExecutionDescriptions?: boolean;
  isStartingPdfGeneration?: boolean;
  setDisplayColumn?: (value: boolean) => void;
  handleGeneratePdfStartExecution?: () => void;
  showShareModal?: () => void;
  enhancedSearch?: boolean;
  isGroupedAds?: boolean;
  onGroupChange?: (isGroupedAds: boolean) => void;
  filterDrawer: boolean;
  setFilterDrawer: (filterDrawer: boolean) => void;
  onSearch?: (value: string) => void;
  expand?: boolean;
  setExpand: (density: boolean) => void;
  fixedAccount?: ISharedAdReview["account"];
  fixedDateRange?: SelectedDates;
};

const HeaderToolbar = ({
  setDisplayColumn,
  showContent = false,
  displayColumn = false,
  validDateRange = false,
  allowSelected,
  selectedAdIds,
  isLoadingPdfExecutionDescriptions,
  isStartingPdfGeneration,
  handleGeneratePdfStartExecution,
  showShareModal,
  isGroupedAds,
  onGroupChange,
  filterDrawer,
  setFilterDrawer,
  onSearch,
  expand,
  setExpand,
  fixedAccount,
  fixedDateRange,
}: HeaderToolbarProps) => {
  const { selectedStore, setSelectedStore } = useSelectedStore();
  const { sortOrder, sortKey, sortItems } = useCampaignManagementSorter();

  const { layout, setLayout, selectedDateRange, setSelectedDateRange } =
    useAdReviewPreferences();

  const isPdfGenerationEnabled = isFeatureEnabled(
    "ENABLE_AD_REVIEW_PDF_GENERATION",
  );

  const toolbarContents: ToolbarButton = useMemo(
    () => ({
      PreviewFormat: {
        disabled: !showContent,
      },
      Column: {
        disabled: !showContent,
        onClick: () => setDisplayColumn?.(!displayColumn),
        extraInfo: {
          iconOnly: true,
        },
      },
      Expand: {
        disabled: !showContent,
        onClick: () => setExpand(!expand),
      },
      SelectWithSort: {
        disabled: !showContent,
        extraInfo: [
          {
            value: "adName",
            label: "Name of Ad",
          },
          {
            value: "status",
            label: "QC Status",
          },
          {
            value: "createdTime",
            label: "Created Date",
          },
          {
            value: "startDate",
            label: "Start Date",
          },
          {
            value: "endDate",
            label: "End Date",
          },
          {
            value: "effectiveStatus",
            label: "Delivery",
          },
          {
            value: "adFormat",
            label: "Ad Format",
          },
          {
            value: "destinationUrl",
            label: "Destination URL",
          },
        ],
      },
      Filter: {
        disabled: !showContent,
        onClick: () => setFilterDrawer(!filterDrawer),
      },
    }),
    [
      displayColumn,
      expand,
      filterDrawer,
      setDisplayColumn,
      setExpand,
      setFilterDrawer,
      showContent,
    ],
  );

  const handleSelectStore = useCallback(
    (storeName, dealers: IAccount[]) => {
      const selectedStore = dealers?.find(
        dealer => dealer.dealer_name === storeName,
      );
      selectedStore &&
        setSelectedStore({
          name: selectedStore.dealer_name,
          fbAccountId: selectedStore.details.facebook.fbAccountId,
        });
    },
    [setSelectedStore],
  );

  const dateRange = useMemo(() => {
    return !!fixedDateRange
      ? fixedDateRange.map(date => moment(transformSecondsToMilliseconds(date)))
      : selectedDateRange;
  }, [fixedDateRange, selectedDateRange]);

  return (
    <div className={styles.toolbar}>
      {fixedAccount ? (
        <Typography.Text strong>{fixedAccount.name}</Typography.Text>
      ) : (
        <SingleAccountSelect
          style={{ maxWidth: "280px", flex: 2 }}
          selectedDealer={selectedStore?.name}
          filters={{ enabled: true, hasFbAccount: true }}
          autoFocus
          onChange={handleSelectStore}
        />
      )}
      <SwitchableDatePicker
        setSelectedDateRange={setSelectedDateRange}
        dateRange={dateRange}
        disabled={!!fixedDateRange}
        validDateRange={validDateRange}
      />
      {isPdfGenerationEnabled && handleGeneratePdfStartExecution && (
        <Button
          onClick={handleGeneratePdfStartExecution}
          type="primary"
          icon={<FilePdfOutlined />}
          disabled={!allowSelected || !selectedAdIds.length}
          loading={isLoadingPdfExecutionDescriptions || isStartingPdfGeneration}
        >
          Generate PDF
        </Button>
      )}
      {showShareModal && (
        <Button
          className={styles.shareButton}
          icon={<ShareAltOutlined style={{ lineHeight: 0 }} />}
          onClick={showShareModal}
          disabled={!allowSelected || !selectedAdIds.length}
        >
          Share
        </Button>
      )}
      {onGroupChange && (
        <Button
          icon={isGroupedAds ? <UngroupOutlined /> : <GroupOutlined />}
          onClick={() => {
            onGroupChange(!isGroupedAds);
          }}
        >
          {isGroupedAds ? "Ungroup" : "Group"} Ads
        </Button>
      )}
      <div className={styles.toolbarSearch}>
        <ToolbarTable
          layout={layout}
          searchPlaceholder="search"
          titleTooltip="Search by any field"
          toolbarContents={toolbarContents}
          onClickLayout={setLayout}
          onSearch={onSearch}
          onSortChange={([columnKey, order]) => {
            sortItems(columnKey, order);
          }}
          sortingOrder={sortKey && sortOrder ? [sortKey, sortOrder] : undefined}
          previewFormat={true}
        />
      </div>
    </div>
  );
};

export default HeaderToolbar;
