import { useEffect, useState } from "react";
import { Popover, message } from "antd";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";

import { ILifestyleImage, IAssetBuilderState } from "shared/types/assetBuilder";
import { ITemplate } from "shared/types/designStudio";

import ImageGalleryUploader from "./imageSelection/ImageGalleryUploader";
import ImageSelectionList from "./imageSelection/ImageSelectionList";

import { INewOrder } from "shared/types/newOrders";
import { IConfigurationState, IConfig } from "shared/types/configuration";

import actions from "redux/rootActions";

import "./ImageSelection.scss";
import { useFetchThemeImages } from "./useFetchThemeImages";
import { useFetchLifestyleImages } from "./useFetchLifestyleImages";

interface ImgSelectionProps {
  config?: IConfig;
  buildPage?: IAssetBuilderState["buildPage"];
  template?: ITemplate;
  selectedImageType: string;
  currOrder: INewOrder | undefined;
  savedSelectImageSearch: string;
  shouldUpdateThemeImage: boolean;
}

interface ImgSelectionHandlers {
  insertLifestyleImage?: (imageUrl: string, imageName: string) => void;
  saveSelectImageSearch: (searcyBy: string) => void;
  setSelectedImageType: (imageType: string) => void;
  setShouldUpdateThemeImage: (shouldUpdateThemeImage: boolean) => void;
  setNumBackgroundImages: (numBackgroundImages: number) => void;
}

const ImageSelection = (props: ImgSelectionProps & ImgSelectionHandlers) => {
  const [showUploader, toggleUploader] = useState<boolean>(false);
  const [lifestyleImages, setLifestyleImages] = useState<ILifestyleImage[]>([]);
  const [selectedInstance] = props.buildPage?.selectedInstance || [];
  const { instance } = selectedInstance || { instance: null };
  const { selectedOffer } = instance || { selectedOffer: null };
  const { template } = props || { template: instance?.template };
  const { offerData } = selectedOffer || { offerData: null };
  const imageSelectionTitle =
    template?.type === "lifestyle" ? "Select image" : "Select Background Asset";

  const { data: themeImages, isFetching: isFetchingThemeImages } =
    useFetchThemeImages({
      template,
      offerData,
      config: props.config,
      onSettled: () => props.setShouldUpdateThemeImage(false),
    });

  const { data: lifeStyleImgs, isFetching: isFetchingLifestyleImages } =
    useFetchLifestyleImages({
      template,
      offerData,
      config: props.config,
      onSettled: () => props.setShouldUpdateThemeImage(false),
    });

  const isFetching = isFetchingThemeImages || isFetchingLifestyleImages;

  useEffect(() => {
    if (!props.config || !offerData) return;

    if (template?.type !== "lifestyle") {
      setLifestyleImages(themeImages || []);
    } else {
      if (template === undefined || template === null) return;

      const { year, make, model } = offerData;

      const parsedYear = parseInt(year);

      if (!parsedYear) {
        message.error('Selected offer has invalid "year". Unable to proceed.');

        return;
      }

      // when uploading LS image, year, make are required.
      // validate these fields from the selected offer.
      if (!make) {
        message.error(
          "Year and Make are required fields for Lifestyle image but selected offer has invalid fields.",
        );

        return;
      }

      if (props.selectedImageType === "account") {
        const imageUrls = offerData.imageUrls?.split(",") || [];
        const accountImages = imageUrls.map(url => {
          return {
            url,
            make,
            model,
            year: parsedYear,
            type: "lifestyle",
          } as ILifestyleImage;
        });
        setLifestyleImages(accountImages);
      } else {
        setLifestyleImages(lifeStyleImgs || []);
      }
    }
  }, [
    lifeStyleImgs,
    offerData,
    props.config,
    props.selectedImageType,
    template,
    themeImages,
  ]);

  useEffect(() => {
    if (
      selectedInstance &&
      (Object.keys(selectedInstance?.instance).length || -1) <= 0
    ) {
      props.setShouldUpdateThemeImage(true);
    }
  }, [props, selectedInstance]);

  if (!template) {
    return <div></div>;
  }
  return (
    <div className={`image-selection selection-container`}>
      <div className="title image-selection">
        <span className="image-selection-title">{imageSelectionTitle}</span>
        {template.type === "lifestyle" && (
          <Popover
            trigger="click"
            visible={showUploader}
            onVisibleChange={visible => {
              toggleUploader(visible);
            }}
            placement="right"
            content={
              <ImageGalleryUploader
                offerData={offerData || undefined}
                toggleUploader={show => {
                  toggleUploader(show);
                }}
                onImageUploadComplete={lifestyleImage => {
                  setLifestyleImages([...lifestyleImages, lifestyleImage]);
                }}
              />
            }
          >
            <span
              className="image-uploader-button"
              onClick={e => {
                e.preventDefault();

                toggleUploader(!showUploader);
              }}
            >
              + Add
            </span>
          </Popover>
        )}
      </div>
      {template.type === "lifestyle" && (
        <div className="image-type-selection-tabs">
          {["brand", "account"].map(type => (
            <div
              key={`image-type-tab-${type}`}
              className={`image-type-tab ${type} ${
                type === props.selectedImageType ? "selected" : ""
              }`}
              onClick={() => props.setSelectedImageType(type)}
            >
              {type === "brand" && "Brand Images"}
              {type === "account" && "Account Images"}
            </div>
          ))}
        </div>
      )}
      <div className="content image-selection-content">
        <ImageSelectionList
          lifestyleImages={lifestyleImages}
          type={template.type || ""}
          fetching={isFetching}
          imageType={props.selectedImageType}
          config={props.config}
          instance={instance}
          insertLifestyleImage={(imageUrl: string, imageName: string) => {
            props.insertLifestyleImage?.(imageUrl, imageName);
          }}
          saveSelectImageSearch={props.saveSelectImageSearch}
          savedSelectImageSearch={props.savedSelectImageSearch}
          currOrder={props.currOrder}
          onLifestyleImageDeleteComplete={id => {
            setLifestyleImages(lifestyleImages.filter(img => img.id !== id));
          }}
          shouldUpdateThemeImage={props.shouldUpdateThemeImage}
          setShouldUpdateThemeImage={props.setShouldUpdateThemeImage}
          setNumBackgroundImages={props.setNumBackgroundImages}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  configuration: IConfigurationState;
  assetBuilder: IAssetBuilderState;
}) => {
  const {
    configuration: { config },
    assetBuilder: { buildPage },
  } = state;
  return {
    config,
    buildPage,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    insertLifestyleImage: (imageUrl: string, imageName: string) => {
      dispatch(
        actions.assetBuilder.insertLifestyleImage({ imageUrl, imageName }),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageSelection);
