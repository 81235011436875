import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { CampaignPlannerTable } from "./campaignPlannerList/CampaignPlannerTable";
import styles from "./CampaignPlannerList.module.scss";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { CampaignPlanner, CampaignPlannerStatus } from "./types";
import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import { useCampaignPlanner } from "./CampaignPlannerContext";
import {
  useCampaignPlannerIds,
  useCampaignPlannerGlobalFilter,
} from "screens/adLibrary/shared/hooks/dataListHooks";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { values } from "lodash";
import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const CampaignPlannerList = () => {
  const {
    createNewCampaign,
    deleteSelection,
    createPlannerInstance,
    selectedInstances,
    deleteSinglePlanner,
    getPlanner,
    copyInstance,
  } = useCampaignPlanner();

  const { selectedIds } = useCampaignPlannerIds();
  const { globalFilter, setGlobalFilter } = useCampaignPlannerGlobalFilter();

  const onDelete = (origin: "Inline" | "Toolbar", data?: CampaignPlanner) => {
    if (origin === "Inline" && data) {
      deleteSinglePlanner(data?.id);
    } else {
      deleteSelection();
    }
  };

  const navigate = useNavigate();

  const instancesNumber = Object.values(selectedInstances).reduce(
    (accum, instances) => accum + instances.length,
    0,
  );

  const generateToolbarContents: (
    record?: CampaignPlanner,
  ) => ToolbarButton = record => {
    return {
      New: {
        disabled: false,
        onClick: createNewCampaign,
        extraInfo: {
          text: "New Campaign",
          tooltip: "Create new Campaign",
        },
      },
      Edit: {
        disabled: selectedIds.length !== 1 && !record,
        icon: <EditOutlined />,
        onClick: () =>
          record ? navigate(`${record.id}`) : navigate(`${selectedIds[0]}`),
        showInContextMenu: true,
        showInInlineMenu: true,
        display: true,
      },
      Delete: {
        disabled: selectedIds.length === 0 && instancesNumber === 0 && !record,
        onClick: () => {
          record ? onDelete("Inline", record) : onDelete("Toolbar");
        },
        showInContextMenu: true,
        showInInlineMenu: true,
        icon: <DeleteOutlined />,
      },
      Duplicate: {
        disabled:
          (values(selectedInstances).flat().length !== 1 ||
            selectedIds.length !== 0) &&
          !record,

        onClick: () => {
          const [plannerId, [instanceId]] =
            Object.entries(selectedInstances)[0];
          copyInstance(plannerId, instanceId);
        },
      },
      NewInstance: {
        disabled:
          (selectedIds.length !== 1 && !record) ||
          (record &&
            record?.status !== CampaignPlannerStatus.INTERNALLY_APPROVED) ||
          (selectedIds.length == 1 &&
            getPlanner(selectedIds[0])?.status !==
              CampaignPlannerStatus.INTERNALLY_APPROVED),
        onClick: () =>
          record
            ? createPlannerInstance(record.id)
            : createPlannerInstance(selectedIds[0]),
        extraInfo: {
          title: "New Instance",
        },
        showInContextMenu: true,
        showInInlineMenu: true,
        icon: <FileAddOutlined />,
      },
    };
  };

  return (
    <div className={styles.container}>
      <ToolbarTable
        searchValue={globalFilter ?? ""}
        toolbarContents={generateToolbarContents()}
        searchPlaceholder="Search"
        titleTooltip="Search campaign planners by name"
        onSearch={setGlobalFilter}
      />
      <TableActionsContextProvider
        renderContextActions={(record?: CampaignPlanner) => (
          <ContextTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
        renderHoverActions={record => (
          <InlineTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
      >
        <CampaignPlannerTable />
      </TableActionsContextProvider>
    </div>
  );
};

export default CampaignPlannerList;
