import { DownloadOutlined, FolderOutlined } from "@ant-design/icons";
import { AdvancedImage, lazyload, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { Button, Typography, message } from "antd";
import { CAM_ENABLED, CAMUpload } from "shared/components/media";
import { defaultImage } from "@cloudinary/url-gen/actions/delivery";
import { pad } from "@cloudinary/url-gen/actions/resize";
import { generativeFill } from "@cloudinary/url-gen/qualifiers/background";

import styles from "./ResizedImage.module.scss";
import { handleDownloadFromUrl } from "../utils/functions/imageInfoUtils";

const cld = new Cloudinary({
  cloud: {
    cloudName: "const",
  },
});

type ResizedImageProps = {
  dimension: {
    id: number;
    label: string;
    width: number;
    height: number;
  };
  publicId: string;
};

const ResizedImage = ({ dimension, publicId }: ResizedImageProps) => {
  const myImage = cld.image(publicId || "");
  myImage
    .resize(
      pad()
        .width(dimension.width)
        .height(dimension.height)
        .background(generativeFill()),
    )
    .delivery(defaultImage("default.png"));

  const imageWidth = (dimension.width / 3) * 2;
  const imageHeight = (dimension.height / 3) * 2;

  const imageUrl = myImage.toURL();

  return (
    <div className={styles.image}>
      <Typography.Text>
        {dimension.label} - {dimension.width} x {dimension.height}{" "}
        <Button
          icon={<DownloadOutlined />}
          type="ghost"
          onClick={() => handleDownloadFromUrl(imageUrl)}
          className={styles.button}
        />
        {CAM_ENABLED && (
          <CAMUpload
            urls={[imageUrl]}
            onComplete={() => {
              message.success("Image saved");
            }}
          >
            <Button
              icon={<FolderOutlined />}
              type="ghost"
              className={styles.button}
            />
          </CAMUpload>
        )}
      </Typography.Text>
      <AdvancedImage
        cldImg={myImage}
        plugins={[placeholder({ mode: "blur" }), lazyload()]}
        style={{
          width: `${imageWidth}px`,
          height: `${imageHeight}px`,
        }}
      />
    </div>
  );
};

export default ResizedImage;
