import { Form, Input } from "antd";
import { memo, useCallback, useState } from "react";
import EditableAdToLoadUrlText from "screens/adLibrary/adLoad/adLoadDrawer/shared/components/adsToLoadTable/EditableAdToLoadUrlText";
import { areFieldsRequired as required } from "screens/adLibrary/adWizard.utils";
import { useStoreLabels } from "shared/hooks/useStoreLabels";
import { ICard } from "shared/types/adLibrary";
import FileUploadDragger from "../shared/FileUploadDragger";
import styles from "./AdCarouselCard.module.scss";
import { useDestinationURLValidator } from "screens/adLibrary/shared/hooks/useDestinationURLValidator";

interface IProps {
  index: number;
  card: ICard;
  patchCard(index: number, newValues: Partial<ICard>): void;
  shouldDisplayErrors?: boolean;
}

const AdCarouselCard = ({
  index,
  card,
  patchCard,
  shouldDisplayErrors,
}: IProps) => {
  const { allStoreLabels } = useStoreLabels();
  const [wasHeadlineTouched, setWasHeadlineTouched] = useState(false);

  const { isValidDestinationURL } = useDestinationURLValidator();

  const hasThumbnailError = shouldDisplayErrors && !card?.thumbnail;
  const hasHeadlineError =
    (shouldDisplayErrors || wasHeadlineTouched) && !card?.headline?.trim();

  const hasDestinationURLError = !isValidDestinationURL(card.destinationUrl);

  const handleChangeFile = useCallback(
    (newCard: ICard) => {
      patchCard(index, newCard);
    },
    [patchCard, index],
  );

  return (
    <div className={styles.adCarouselCard}>
      <Form.Item
        validateStatus={hasThumbnailError ? "error" : "success"}
        help={hasThumbnailError && "Please add an asset"}
        style={{ width: 200 }}
        data-cy="card-file-item"
      >
        <FileUploadDragger
          fileUrls={card}
          handleChangeFile={handleChangeFile}
          placeholder={(index + 1).toString()}
        />
      </Form.Item>
      <Form.Item
        label="Headline"
        validateStatus={hasHeadlineError ? "error" : "success"}
        help={hasHeadlineError && "Please add a headline"}
        required={required}
      >
        <Input
          value={card?.headline || ""}
          onChange={event => {
            setWasHeadlineTouched(true);
            patchCard(index, { ...card, headline: event.target.value });
          }}
          data-cy="card-headline-input"
        />
      </Form.Item>
      <Form.Item label="Description">
        <Input.TextArea
          value={card?.description || ""}
          onChange={event =>
            patchCard(index, { ...card, description: event.target.value })
          }
          data-cy="description-textarea"
        />
      </Form.Item>

      <Form.Item
        label="Destination Url"
        validateStatus={hasDestinationURLError ? "error" : "success"}
        help={
          hasDestinationURLError &&
          "Please use “https://” or “http://” to format url correctly"
        }
      >
        <EditableAdToLoadUrlText
          showInputOnly
          linkValue={card?.destinationUrl || ""}
          urlLabels={allStoreLabels}
          onChange={value => {
            patchCard(index, { ...card, destinationUrl: value });
          }}
          disableLabelPopulation
        />
      </Form.Item>
    </div>
  );
};

export default memo(AdCarouselCard);
