import { useLocation } from "react-router-dom";
import { QP_BREADCRUMB } from "./constants";

export const useQPBreadcrumb = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const stack = params.get(QP_BREADCRUMB)?.split(",") || [];
  const [current] = stack.slice(-1);

  const toFolder = (id: string) => {
    const params = new URLSearchParams(search);

    if (id === "0") {
      params.delete(QP_BREADCRUMB);
    } else {
      const i = stack.indexOf(id);
      const newStack = i > -1 ? stack.slice(0, i + 1) : stack.concat(id);
      params.set(QP_BREADCRUMB, newStack.join());
    }

    return params.toString();
  };

  return { stack, current, toFolder };
};
