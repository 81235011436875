import { DownloadOutlined, FolderOutlined } from "@ant-design/icons";
import { Cloudinary } from "@cloudinary/url-gen";
import { defaultImage } from "@cloudinary/url-gen/actions/delivery";
import { pad } from "@cloudinary/url-gen/actions/resize";
import { generativeFill } from "@cloudinary/url-gen/qualifiers/background";
import { Button, Progress, Typography, message } from "antd";
import { useMemo, useState } from "react";
import FileUploadDragger from "screens/adLibrary/adWizard/shared/FileUploadDragger";
import { CAMUpload, CAM_ENABLED } from "shared/components/media";
import useIsImageUploaded from "shared/hooks/genAI/useIsImageUploaded";
import useResizeImage from "shared/hooks/genAI/useResizeImage";
import { AiResizeIcon } from "shared/icons/AiResizeIcon";
import { IUploadMediaUrls } from "utils/uploadMedia";
import DimensionsPicker, { Dimension } from "../components/DimensionsPicker";
import ResizedImage from "../components/ResizedImage";
import ToolPicker from "../components/ToolPicker";
import { defaultResizeDimensions } from "../utils/constants";
import { handleDownloadAllFromUrl } from "../utils/functions/imageInfoUtils";

import styles from "./Resize.module.scss";

const cld = new Cloudinary({
  cloud: {
    cloudName: "const",
  },
});

const Resize = () => {
  const [customDimensions, setCustomDimensions] = useState<Dimension[]>([]);
  const [dimensions, setDimensions] = useState<Dimension[]>(
    defaultResizeDimensions,
  );

  const [progress, setProgress] = useState(0);

  const [fileUrls, setFileUrls] = useState<IUploadMediaUrls>({
    thumbnail: "",
    videoUrl: "",
    assetId: "",
  });

  const { data, isFetching, handleResizeImage, cancel } = useResizeImage({
    url: fileUrls.thumbnail || "",
  });

  const isImageUploaded = useIsImageUploaded({ id: data?.id || "" });

  const isResizing =
    isFetching || (progress < 100 && progress > 0 && !isImageUploaded);

  const handleResize = async () => {
    if (isResizing) {
      setProgress(100);
      cancel();
      return;
    }

    if (fileUrls.thumbnail) {
      handleResizeImage();
      for (let i = 0; i <= 100; i += 5) {
        setProgress(i);
        await new Promise(resolve => setTimeout(resolve, 1000));
        if (!isFetching && isImageUploaded) setProgress(100);
      }
    }
  };

  const handleSelectDimension = (id: number) => {
    setDimensions(prevDimensions =>
      prevDimensions.map(dimension =>
        dimension.id === id
          ? { ...dimension, selected: !dimension.selected }
          : dimension,
      ),
    );
  };

  const urls = useMemo(() => {
    return [...dimensions, ...customDimensions]
      .filter(
        dimension =>
          dimension.selected === true && dimension.height && dimension.width,
      )
      .map(dimension => {
        const myImage = cld.image(data?.publicId || "");
        myImage
          .resize(
            pad()
              .width(dimension.width)
              .height(dimension.height)
              .background(generativeFill()),
          )
          .delivery(defaultImage("default.png"));

        return myImage.toURL();
      });
  }, [dimensions, customDimensions, data]);

  const handleDownloadAll = () => {
    handleDownloadAllFromUrl(urls);
  };

  return (
    <div className={styles.resizeContainer}>
      <div className={styles.leftPanel}>
        <ToolPicker />
        <div className={styles.inputSectionContainer}>
          <div>
            <Typography.Title level={5}>Resize image</Typography.Title>
            <Typography.Text>Upload your image to resize it</Typography.Text>
          </div>
          <FileUploadDragger
            fileUrls={fileUrls}
            handleChangeFile={setFileUrls}
            placeholder="Upload"
          />
          <DimensionsPicker
            dimensions={dimensions}
            customDimensions={customDimensions}
            setCustomDimensions={setCustomDimensions}
            handleSelectDimension={handleSelectDimension}
          />
          {isResizing && <Progress percent={progress} showInfo={false} />}
          <Button
            type="primary"
            onClick={handleResize}
            disabled={
              !fileUrls.thumbnail ||
              dimensions.filter(d => d.selected).length === 0
            }
          >
            {isResizing && !isImageUploaded
              ? "Cancel"
              : data && data.url
              ? "Resize Again"
              : "Resize"}
          </Button>
        </div>
      </div>
      <div className={styles.resultsContainer}>
        {data?.publicId && !isFetching && isImageUploaded && (
          <div className={styles.buttonsRow}>
            <Button onClick={handleDownloadAll}>
              <DownloadOutlined />
              Download All
            </Button>
            {CAM_ENABLED && (
              <CAMUpload
                urls={urls}
                onComplete={() => {
                  message.success("All images saved");
                }}
              >
                <Button type="primary">
                  <FolderOutlined /> Save All
                </Button>
              </CAMUpload>
            )}
          </div>
        )}
        {data?.publicId && !isFetching && isImageUploaded ? (
          [...dimensions, ...customDimensions]
            .filter(
              dimension =>
                dimension.selected === true &&
                dimension.height &&
                dimension.width,
            )
            .map(dimension => {
              return (
                <ResizedImage
                  dimension={dimension}
                  publicId={data?.publicId}
                  key={dimension.id}
                />
              );
            })
        ) : (
          <div className={styles.emptyState}>
            <AiResizeIcon height={100} width={100} color="#1890ff" />
            Resizing images made simpler and smarter
          </div>
        )}
      </div>
    </div>
  );
};

export default Resize;
