import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { AppThunk } from "redux/store";
import { updateAdCard } from "screens/adLibrary/adImport/adCompleteImportDrawer/adImportTable/adImportTableContainer/adImportTable.utils";
import { ImportAdData } from "screens/adLibrary/adImport/AdImportDrawer";
import { getAdValidations } from "screens/adLibrary/adImport/adValidationUtils";
import {
  ImportNotificationProps,
  openProcessingImportNotification,
  setDisplayCloseIcon,
} from "screens/adLibrary/adImport/ProcessingImportNotification";
import {
  getAdsInfoFromCsvData,
  mapCsvDataToAd,
} from "screens/adLibrary/adImport/processingImportUtils";
import { initAdLoadParameters } from "screens/adLibrary/adLoad/utils";
import {
  IFacebookAccount,
  IFacebookAdset,
  IFacebookCampaign,
} from "screens/adLibrary/facebookUtils/types";
import {
  AdLoadRuleConflictIdDict,
  AdLoadStepKey,
  ChildCard,
  DelimterObject,
  FacebookAdLoadDictionary,
  FacebookAdPart,
  FacebookDataIdsByAd,
  IAd,
  IAdExportRequest,
  IAdLibraryState,
  IAdLoad,
  IAdLoadDestination,
  IAdLoadFacebookDataDictionary,
  IAdLoadHistoryItem,
  IAdLoadParameters,
  IAdLoadParametersRuleset,
  IAdLoadParametersRulesPresetNamesObject,
  IAdLoadReview,
  IAdToLoadData,
  IFacebookAdToLoad,
  TaxonomiesObject,
} from "shared/types/adLibrary";
import { promiseAllBatched } from "utils/helpers.promises";

const initialState: IAdLibraryState = {
  isAdLoadVisible: false,
  selectedAdIdForSummary: null,
  adLoad: {
    currentStep: AdLoadStepKey.DESTINATION_SELECTION,
    selectedAdForParameters: null,
    prevSelectedAdForParameters: null,
    conflictingAdShellIdForEdit: null,
    adLoadParameters: initAdLoadParameters,
    adLoadHistory: null,
    facebookAdLoadDict: null,
    facebookDataIdsByAd: null,
    selectedAds: null,
    destination: null,
    review: null,
  },
  adImport: {
    displayImportTable: false,
    adsToImport: [],
    cancelImport: false,
    selectedAdIds: [],
    processingAds: false,
    adValidations: [],
  },
  adReview: {
    pdfExecutionArns: [],
  },
  adExportRequests: [],
};

const adLibrarySlice = createSlice({
  name: "adLibrary",
  initialState,
  reducers: {
    setSelectedAdForSummary(
      state,
      { payload: selectedAdIdForSummary }: PayloadAction<string | null>,
    ) {
      return {
        ...state,
        selectedAdIdForSummary,
      };
    },
    setSelectedFacebookAccounts(
      state,
      { payload }: PayloadAction<IFacebookAccount[] | null>,
    ) {
      return {
        ...state,
        selectedFacebookAccounts: payload,
      };
    },
    setSelectedAdForAdLoadParameters(
      state,
      {
        payload,
      }: PayloadAction<{
        selectedAd?: IAd | null;
        prevSelectedAd?: IAd | null;
      }>,
    ) {
      return {
        ...state,
        adLoad: {
          ...state.adLoad,
          selectedAdForParameters: payload.selectedAd,
          prevSelectedAdForParametes: payload.prevSelectedAd || null,
        } as IAdLoad,
      };
    },
    setAdLoadParametersForAd(
      state,
      {
        payload,
      }: PayloadAction<{
        adShellId: string;
        prop: keyof IAdLoadParameters;
        data:
          | IAdLoadParametersRuleset
          | IAdLoadParametersRulesPresetNamesObject
          | TaxonomiesObject
          | DelimterObject
          | null;
      }>,
    ) {
      const propKey = payload.prop as keyof IAdLoadParameters;
      return {
        ...state,
        adLoad: {
          ...state.adLoad,
          adLoadParameters: {
            ...state.adLoad.adLoadParameters,
            [propKey]: {
              ...(state.adLoad?.adLoadParameters?.[propKey] ?? {}),
              [payload.adShellId]: payload.data,
            },
          } as IAdLoadParameters,
        },
      };
    },
    setFacebookAdLoadDictForAd(
      state,
      {
        payload,
      }: PayloadAction<{
        adShellId: string | null;
        adPart: FacebookAdPart | "all";
        data:
          | IAdLoadFacebookDataDictionary
          | IFacebookCampaign[]
          | IFacebookAdset[]
          | IFacebookAdToLoad[]
          | FacebookAdLoadDictionary;
      }>,
    ) {
      if (!payload.adShellId) {
        return {
          ...state,
          adLoad: {
            ...state.adLoad,
            facebookAdLoadDict: {
              ...(payload.data as any), // TODO: check this type
            },
          },
        };
      }

      if (payload.adPart === "all") {
        return {
          ...state,
          adLoad: {
            ...state.adLoad,
            facebookAdLoadDict: {
              ...(state.adLoad.facebookAdLoadDict || {}),
              [payload.adShellId]: payload.data,
            },
          },
        };
      }

      const currentFacebookDataDict = (state.adLoad.facebookAdLoadDict || {})[
        payload.adShellId
      ] || {
        campaign: [],
        adset: [],
        ad: [],
      };

      return {
        ...state,
        adLoad: {
          ...state.adLoad,
          facebookAdLoadDict: {
            ...(state.adLoad.facebookAdLoadDict || {}),
            [payload.adShellId]: {
              ...currentFacebookDataDict,
              [payload.adPart as FacebookAdPart]: payload.data,
            },
          },
        },
      };
    },
    updateFacebookAdLoadDictionary(
      state,
      { payload }: PayloadAction<FacebookAdLoadDictionary>,
    ) {
      state.adLoad.facebookAdLoadDict = payload;
    },
    updateFacebookDataIdsByAd(
      state,
      { payload }: PayloadAction<FacebookDataIdsByAd>,
    ) {
      state.adLoad.facebookDataIdsByAd = payload;
    },
    setAdLoadConflictIdDict(
      state,
      {
        payload,
      }: PayloadAction<{
        adShellId?: string | null;
        data: string[] | AdLoadRuleConflictIdDict;
      }>,
    ) {
      if (!payload.adShellId) {
        return {
          ...state,
          adLoad: {
            ...state.adLoad,
            adLoadParameters: {
              ...state.adLoad.adLoadParameters,
              conflicts: payload.data,
            } as IAdLoadParameters,
          },
        };
      }

      return {
        ...state,
        adLoad: {
          ...state.adLoad,
          adLoadParameters: {
            ...state.adLoad.adLoadParameters,
            conflicts: {
              ...(state.adLoad.adLoadParameters?.conflicts || {}),
              [payload.adShellId]: payload.data || [],
            },
          } as IAdLoadParameters,
        },
      };
    },
    setConflictingAdShellIdForEdit(
      state,
      { payload }: PayloadAction<{ adShellId: string | null }>,
    ) {
      return {
        ...state,
        adLoad: {
          ...state.adLoad,
          conflictingAdShellIdForEdit: payload.adShellId,
        },
      };
    },
    setAdLoadState(
      state,
      {
        payload,
      }: PayloadAction<{
        prop?: keyof IAdLoad;
        triggerUpdateProp?: IAdLoad["triggerUpdateProp"];
        data:
          | IAdLoad
          | IAdLoadParameters
          | IAdLoadHistoryItem
          | IAdLoadDestination
          | IAdLoadReview
          | FacebookAdLoadDictionary
          | IAdToLoadData[]
          | IAd[]
          | IAd
          | string[]
          | string
          | null;
      }>,
    ) {
      if (!payload.prop) {
        return {
          ...state,
          adLoad: payload.data as IAdLoad,
        };
      }

      return {
        ...state,
        adLoad: {
          ...state.adLoad,
          [payload.prop as keyof IAdLoad]: payload.data,
          triggerUpdateProp: payload.triggerUpdateProp,
        } as IAdLoad,
      };
    },
    updateAdLoadReview(
      state,
      {
        payload,
      }: PayloadAction<{
        prop?: keyof IAdLoadReview;
        triggerUpdateProp?: IAdLoad["triggerUpdateProp"];
        data: string[] | IAdLoadReview | IAdToLoadData[];
      }>,
    ) {
      if (!payload.prop) {
        return {
          ...state,
          adLoad: {
            ...state.adLoad,
            review: payload.data as IAdLoadReview,
          },
        };
      }

      return {
        ...state,
        adLoad: {
          ...state.adLoad,
          review: {
            ...state.adLoad.review,
            [payload.prop]: payload.data,
          },
          triggerUpdateProp: payload.triggerUpdateProp,
        } as IAdLoad,
      };
    },
    resetAdLoadChangedProp(state) {
      return {
        ...state,
        adLoad: {
          ...state.adLoad,
          triggerUpdateProp: undefined,
        },
      };
    },
    finishProcessingAds(state, action: PayloadAction<{ ads: IAd[] }>) {
      const ads: IAd[] = action.payload.ads;
      const adValidations = ads.map(getAdValidations);
      const adIdsWithErrors = adValidations
        .filter(validation => validation.hasErrors)
        .map(validation => validation.adId);
      const adsWithoutErrors = ads
        .filter(ad => !adIdsWithErrors.includes(ad.id))
        .map(ad => ad.id);

      return {
        ...state,
        adImport: {
          ...state.adImport,
          adsToImport: ads,
          selectedAdIds: adsWithoutErrors,
          processingAds: false,
          adValidations,
        },
      };
    },
    startProcessingAds(state) {
      return {
        ...state,
        adImport: {
          ...state.adImport,
          processingAds: true,
        },
      };
    },
    cancelProcessingAds(state) {
      return {
        ...state,
        adImport: {
          ...state.adImport,
          cancelImport: true,
          processingAds: false,
        },
      };
    },
    setDisplayImportTable(
      state,
      { payload: displayImportTable }: PayloadAction<boolean>,
    ) {
      return {
        ...state,
        adImport: {
          ...state.adImport,
          displayImportTable,
        },
      };
    },
    resetAdImport(state) {
      return {
        ...state,
        adImport: initialState.adImport,
      };
    },
    setAdImport(state, action: PayloadAction<{ adId: string; adUpdate: IAd }>) {
      const { adId, adUpdate } = action.payload;
      const childAdCardUpdate = (adUpdate as ChildCard).isChild
        ? (adUpdate as ChildCard)
        : null;

      const editedAds = state.adImport.adsToImport.map(ad => {
        if (!!childAdCardUpdate && ad.id === childAdCardUpdate.parentAdId) {
          return updateAdCard(ad, childAdCardUpdate);
        }
        return ad.id === adId ? adUpdate : ad;
      });
      const editedAdValidations = editedAds.map(getAdValidations);
      const [editedAdValidation] = editedAdValidations.filter(
        validation => validation.adId === adId,
      );

      const newSelectedAdIds = editedAdValidation?.hasErrors
        ? state.adImport.selectedAdIds.filter(
            selectedAdId => selectedAdId !== editedAdValidation.adId,
          )
        : state.adImport.selectedAdIds;

      return {
        ...state,
        adImport: {
          ...state.adImport,
          adsToImport: editedAds,
          adValidations: editedAdValidations,
          selectedAdIds: newSelectedAdIds,
        },
      };
    },
    setAdsImport(state, action: PayloadAction<{ ads: IAd[] }>) {
      const ads: IAd[] = action.payload.ads;
      return {
        ...state,
        adImport: {
          ...state.adImport,
          adsToImport: ads,
        },
      };
    },
    setSelectedAdIds(
      state,
      action: PayloadAction<{ selectedAdIds: string[] }>,
    ) {
      return {
        ...state,
        adImport: {
          ...state.adImport,
          selectedAdIds: action.payload.selectedAdIds,
        },
      };
    },
    toggleIsAdLoadVisible(
      state,
      { payload: isAdLoadVisible }: PayloadAction<boolean>,
    ) {
      return {
        ...state,
        isAdLoadVisible,
      };
    },
    setPdfExecutionArns(
      state,
      action: PayloadAction<{ pdfExecutionArns: string[] }>,
    ) {
      return {
        ...state,
        adReview: {
          ...state.adReview,
          pdfExecutionArns: action.payload.pdfExecutionArns,
        },
      };
    },
    setTaxonomies(state, action: PayloadAction<TaxonomiesObject>) {
      return {
        ...state,
        adLoad: {
          ...state.adLoad,
          adLoadParameters: {
            ...state.adLoad.adLoadParameters,
            taxonomies: action.payload,
          } as IAdLoadParameters,
        },
      };
    },
    setDelimiter(state, action: PayloadAction<DelimterObject>) {
      return {
        ...state,
        adLoad: {
          ...state.adLoad,
          adLoadParameters: {
            ...state.adLoad.adLoadParameters,
            delimiters: action.payload,
          } as IAdLoadParameters,
        },
      };
    },
    setAdExportRequests(
      state,
      action: PayloadAction<{ adExportRequests: IAdExportRequest[] }>,
    ) {
      state.adExportRequests = action.payload.adExportRequests;
    },
  },
});

export const processImportAds =
  (
    adsData: ImportAdData[],
    initialImportNotificationProps: Partial<ImportNotificationProps>,
    navigate: NavigateFunction,
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startProcessingAds());

    const user = getState().auth.user;

    const getCancelImport = () => getState().adLibrary.adImport.cancelImport;

    let importedAds = 0;
    const importNotificationProps: ImportNotificationProps = {
      notificationKey: "processing-ad-import",
      columnsIgnored: 0,
      importedItems: importedAds,
      totalItems: adsData.length,
      handleCancel: () => dispatch(cancelProcessingAds()),
      handleReview: () => {
        navigate("/ad-library");
        dispatch(setDisplayImportTable(true));
      },
      itemLabel: "Ads",
      ...initialImportNotificationProps,
    };

    openProcessingImportNotification(importNotificationProps);

    const newAds: IAd[] = await promiseAllBatched(
      adsData.map(adData => async () => {
        const newAd = await mapCsvDataToAd(adData, user);
        ++importedAds;

        if (!getCancelImport()) {
          openProcessingImportNotification({
            ...importNotificationProps,
            importedItems: importedAds,
          });
        }

        return newAd;
      }),
      100,
    );

    if (getCancelImport()) {
      dispatch(resetAdImport());
      return;
    }

    setDisplayCloseIcon(false);
    dispatch(finishProcessingAds({ ads: newAds }));
  };

export const fetchAdsInfo = async (adsData: ImportAdData[]) => {
  const data = await Promise.all(adsData.map(getAdsInfoFromCsvData));
  return data.flat().filter(info => info?.file !== null);
};

export const {
  cancelProcessingAds,
  finishProcessingAds,
  resetAdImport,
  resetAdLoadChangedProp,
  setAdImport,
  setAdLoadConflictIdDict,
  setAdLoadParametersForAd,
  setAdLoadState,
  updateAdLoadReview,
  setAdsImport,
  setConflictingAdShellIdForEdit,
  setDisplayImportTable,
  setFacebookAdLoadDictForAd,
  updateFacebookAdLoadDictionary,
  updateFacebookDataIdsByAd,
  setPdfExecutionArns,
  setSelectedAdForAdLoadParameters,
  setSelectedAdForSummary,
  setSelectedAdIds,
  setSelectedFacebookAccounts,
  startProcessingAds,
  toggleIsAdLoadVisible,
  setTaxonomies,
  setDelimiter,
  setAdExportRequests,
} = adLibrarySlice.actions;

export default adLibrarySlice.reducer;
