import { useCallback } from "react";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { setPdfExecutionArns } from "redux/adLibrary/adLibrary.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";

export const usePdfExecutionArns = () => {
  const pdfExecutionArns = useAppSelector(
    state => state.adLibrary.adReview.pdfExecutionArns,
  );

  const dispatch = useAppDispatch();
  const handleSetPdfExecutionArns = useCallback(
    (pdfExecutionArns: string[]) =>
      dispatch(setPdfExecutionArns({ pdfExecutionArns })),
    [dispatch],
  );

  return { pdfExecutionArns, handleSetPdfExecutionArns };
};
