import { Steps } from "antd";
import { steps } from "shared/constants/assetExporter";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";

const { Step } = Steps;

export const StepsConfig = () => {
  const { uploadType, drawerMode, step, latestStep } = useAppSelector(
    state => state.assetExporter.sourceFeed.feedDrawer,
  );
  const actions = useAdEngineActions();
  const isCustom = uploadType === "custom";
  const isUpdate = drawerMode === "UPDATE";
  const createSteps = isCustom ? steps.filter(s => s !== "Scheduling") : steps;
  const updateSteps = isCustom
    ? steps.filter(s => s !== "Map Columns" && s !== "Scheduling")
    : steps.filter(s => s !== "Map Columns");
  const availableSteps = isUpdate ? updateSteps : createSteps;

  return (
    <Steps direction="vertical" size="small" current={step}>
      {availableSteps.map((currStep, idx) => {
        const isDisabled = latestStep < idx;
        return (
          <Step
            style={{ cursor: isDisabled ? "default" : "pointer" }}
            key={idx}
            title={currStep}
            onClick={() => !isDisabled && actions.setStep(idx)}
            disabled={isDisabled}
          />
        );
      })}
    </Steps>
  );
};
