import { CloseOutlined } from "@ant-design/icons";
import { notification, Spin, Tooltip } from "antd";
import { IconType } from "antd/lib/notification";
import moment from "moment";
import { useEffect } from "react";
import AlexiaCopyToClipboard from "shared/components/CopyLink";
import { mmddyyyyTimeFormat } from "shared/constants/dataManagement";
import { useGeneratePdfDescribeExecutions } from "shared/hooks/adLibrary/adReview/useGeneratePdfExecutions";
import { IPdfDescribeExecutionResponseData } from "shared/types/adReview";
import { getDuration } from "utils/helpers.date";
import { usePdfExecutionArns } from "./usePdfExecutionArns";

/**
 * This hook will display a notification for every PDF you are generating from ad review.
 * It will check the redux store to get the arns of the PDFs you are generating.
 */
export function usePdfGeneratorNotification() {
  const { pdfExecutionArns, handleSetPdfExecutionArns } = usePdfExecutionArns();

  const pdfExecutionDescriptions =
    useGeneratePdfDescribeExecutions(pdfExecutionArns);

  useEffect(() => {
    pdfExecutionDescriptions.forEach(({ data: execution }) => {
      if (!execution) return;

      const getNotificationType = (): IconType | undefined => {
        if (execution.status === "SUCCEEDED") {
          return "success";
        }
        if (execution.status === "FAILED") {
          return "error";
        }
      };

      notification.open({
        type: getNotificationType(),
        key: execution?.executionArn,
        message: "PDF Generation",
        icon: execution.status === "RUNNING" ? <Spin /> : undefined,
        description: <Description execution={execution} />,
        duration: null,
        closeIcon: <CloseOutlined />,
        onClose: () =>
          handleSetPdfExecutionArns(
            pdfExecutionArns.filter(arn => arn !== execution?.executionArn),
          ),
        placement: "bottomRight",
      });
    });
  }, [pdfExecutionArns, handleSetPdfExecutionArns, pdfExecutionDescriptions]);
}

export const useAdReviewPdfGeneratorNotification = usePdfGeneratorNotification;

const Description = ({
  execution: { output, status, startDate, stopDate },
}: {
  execution: IPdfDescribeExecutionResponseData;
}) => {
  const durationString = getDuration(startDate, stopDate);

  const metadata = (
    <div>
      <div>
        <b>Start Date: </b>
        {moment(startDate).format(mmddyyyyTimeFormat)}
      </div>
      {stopDate && (
        <Tooltip title={durationString} placement="left">
          <div>
            <b>End Date: </b>
            {moment(stopDate).format(mmddyyyyTimeFormat)}
          </div>
        </Tooltip>
      )}
    </div>
  );

  if (status === "SUCCEEDED")
    return (
      <div>
        {metadata}
        <span>
          <b>PDF URL: </b>
          <a href={output.pdfUrl} target="_blank" rel="noreferrer">
            Open
          </a>{" "}
          <AlexiaCopyToClipboard
            text={output.pdfUrl}
            copyTooltipText="Copy link to PDF"
          />
        </span>
      </div>
    );

  if (status === "RUNNING") {
    return <div>{metadata}</div>;
  }

  return (
    <div>
      {metadata}
      <div>The PDF Generation Failed</div>
    </div>
  );
};
