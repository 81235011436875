import { notification } from "antd";
import moment from "moment";
import { useCallback } from "react";
import {
  useGeneratePdfStartExecution,
  useIsLoadingPdfExecutionDescriptions,
} from "shared/hooks/adLibrary/adReview/useGeneratePdfExecutions";
import { useUser } from "shared/hooks/useUser";
import { SelectedStore } from "shared/hooks/userPreferences/useSelectedStore";
import { PDFDataPayload } from "shared/types/adReview";
import { SelectedDates } from "shared/types/campaignManagement";
import { usePdfExecutionArns } from "./usePdfExecutionArns";
import { extractPrimaryIdsFromCarouselAds } from "../utils/helpers.ad";

interface IUseAds {
  selectedIds: string[];
  selectedStore?: SelectedStore;
  selectedDateRange: SelectedDates;
}

const usePdfGeneration = ({
  selectedIds,
  selectedStore,
  selectedDateRange,
}: IUseAds) => {
  const user = useUser();

  const {
    mutate: generatePdfStartExecution,
    isLoading: isStartingPdfGeneration,
  } = useGeneratePdfStartExecution();

  const isLoadingPdfExecutionDescriptions =
    useIsLoadingPdfExecutionDescriptions();

  const { pdfExecutionArns, handleSetPdfExecutionArns } = usePdfExecutionArns();

  const handleGeneratePdfStartExecution = useCallback(() => {
    const fbAccountId = selectedStore?.fbAccountId;
    if (!fbAccountId) return;

    const allPrimaryAdsIds = extractPrimaryIdsFromCarouselAds(selectedIds);
    const userFullName = user?.name;

    const formatNumericDate = (date: number) =>
      date ? moment(date)?.format("YYYY/MM/DD") : undefined;

    const payload: PDFDataPayload = {
      pdfData: {
        ads: allPrimaryAdsIds.map(adId => ({ adId })),
        account_id: fbAccountId,
        account_name: selectedStore.name, // Using dealer name instead of account name
        since: formatNumericDate(selectedDateRange?.[0]),
        until: formatNumericDate(selectedDateRange?.[1]),
        fullname: userFullName,
        sender: user?.email,
        timezone:
          Intl?.DateTimeFormat().resolvedOptions().timeZone ||
          "America/New_York",
      },
    };
    generatePdfStartExecution(payload, {
      onSuccess: data => {
        handleSetPdfExecutionArns(pdfExecutionArns.concat(data.executionArn));
      },
      onError: () => {
        notification.error({
          message:
            "Something went wrong while starting the PDF generation process",
          placement: "bottomRight",
        });
      },
    });
  }, [
    generatePdfStartExecution,
    handleSetPdfExecutionArns,
    pdfExecutionArns,
    selectedIds,
    selectedDateRange,
    selectedStore?.fbAccountId,
    selectedStore?.name,
    user?.email,
    user?.name,
  ]);

  return {
    isStartingPdfGeneration,
    isLoadingPdfExecutionDescriptions,
    handleGeneratePdfStartExecution,
  };
};
export default usePdfGeneration;
