import API from "services";
import {
  useIsFetching,
  useMutation,
  useQueries,
} from "react-query";
import {
  IPdfDescribeExecutionResponseData,
  IPdfStartExecutionResponseData,
  PDFDataPayload,
} from "shared/types/adReview";

export const useGeneratePdfStartExecution = () => {
  return useMutation<IPdfStartExecutionResponseData, Error, PDFDataPayload>(
    generatePdfStartExecution,
  );
};

const pdfExecutionsKey = "adReview/pdfExecutions";

export const useGeneratePdfDescribeExecutions = (executionArns: string[]) => {
  return useQueries(
    executionArns.map(executionArn => {
      return {
        queryKey: [pdfExecutionsKey, executionArn],
        queryFn: () => generatePdfDescribeExecution(executionArn),
        refetchInterval: (data: any) => {
          const executionData = data as IPdfDescribeExecutionResponseData;
          return !executionData || executionData.status === "RUNNING"
            ? 3000 // polling every 3 seconds when the step function is still running
            : false;
        },
        staleTime: Infinity,
      };
    }),
  );
};

export const useIsLoadingPdfExecutionDescriptions = () => {
  return (
    useIsFetching(pdfExecutionsKey, {
      predicate: query => {
        return query.state.status === "loading";
      },
    }) > 0
  );
};

const generatePdfStartExecution = async (pdfData: PDFDataPayload) => {
  const { result, error } =
    await API.services.adLibrary.generatePdfStartExecution(pdfData);

  if (!result) {
    throw Error(
      error?.message || "Something went wrong while starting the execution.",
    );
  }

  return result;
};

const generatePdfDescribeExecution = async (executionArn: string) => {
  const { result, error } =
    await API.services.adLibrary.generatePdfDescribeExecution(executionArn);

  if (!result) {
    throw Error(
      error?.message ||
        "Something went wrong while getting the execution details.",
    );
  }

  return result;
};
