import React, { memo } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Tooltip, Modal, message } from "antd";
import { isEqual } from "lodash";
import { CAM_ENABLED } from "shared/components/media";
import { ILifestyleImage } from "shared/types/assetBuilder";
import { IConfig } from "shared/types/configuration";
import API from "services";

import "./Thumbnail.scss";
import GenericError from "shared/errors/GenericError";
import { TTemplateType } from "shared/types/designStudio";
import { isSameImage } from "../../ImageSelection.utils";

interface ThumbnailProps {
  lifestyleImage: ILifestyleImage;
  selectedLifestyleImageUrl?: string;
  type: TTemplateType | "";
  imageType: string;
  hoveredImageId?: string;
  config?: IConfig;
}

interface ThumbnailHandlers {
  onSelect?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDeleteComplete?: (id: string) => void;
  setHoveredImageId?: (id?: string) => void;
  convertUrlToCDN: (url?: string) => string;
}
const getName = (ymmt: boolean, yearMakeName: string) => {
  if (ymmt) return yearMakeName;
  return "";
};

const getAssetUrl = (isVideo: boolean, url: string) => {
  if (isVideo) {
    return url.replace("mp4", "png");
  }
  if (/\.(jpe?g|png)$/i.test(url)) {
    // CP-1575 - Restore this line to use the 25% images after they are processed in s3
    // return url.replace("lifestyle-images", "lifestyle-images-25");
    return url;
  }
  return url;
};

const Thumbnail = (props: ThumbnailProps & ThumbnailHandlers) => {
  const thumbnailUrl = props.lifestyleImage.url || "";
  const { name, year, make, model, trim } = props.lifestyleImage;
  const isVideo = thumbnailUrl.endsWith("mp4");
  const assetUrl = getAssetUrl(isVideo, thumbnailUrl);
  const isCarCut = props.type === "carcut" || props.type === "";
  const isYMMT = !!(year && make && model);
  const yearMakeName = [year, make, model, trim].filter(Boolean).join("_");
  const imageName = isCarCut ? name : getName(isYMMT, yearMakeName);

  return (
    <div
      onClick={props.onSelect}
      className={`lifestyle-image-thumbnail-container ${
        isSameImage(props.selectedLifestyleImageUrl, thumbnailUrl)
          ? "selected"
          : ""
      }`}
    >
      {props.type === "lifestyle" && props.imageType === "oem" && (
        <div className="layover" hidden={CAM_ENABLED}>
          <div className="button-container">
            <span className="icon">
              <Tooltip title="Delete">
                <DeleteOutlined
                  className="ls-thumbnail-delete-button"
                  style={{ color: "white" }}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();

                    Modal.confirm({
                      title: "Delete Lifestyle Image",
                      content:
                        "Do you really want to delete this lifestyle image?",
                      okText: "Delete",
                      okType: "primary",
                      okButtonProps: { danger: true },
                      onOk: () => {
                        // delete
                        type responseType = {
                          result: {
                            deletedLifestyleImage: ILifestyleImage;
                          };
                          error: GenericError;
                        };

                        return new Promise<responseType>(resolve => {
                          if (!props.config) {
                            message.error(
                              "Due to an error, unable to proceed.",
                            );

                            return;
                          }

                          const { services } = props.config;

                          const request: RequestInfo = new Request(
                            services.assetBuilder.uploadLifestyleImageUrl,
                            {
                              method: "DELETE",
                              body: JSON.stringify({
                                lifestyleImage: {
                                  id: props.lifestyleImage.id,
                                  make: props.lifestyleImage.make,
                                },
                              }),
                            },
                          );
                          API.send<responseType>(request).then(response => {
                            // result will contain { deletedLifestyleImage: { ... }}
                            resolve(response);
                          });
                        }).then(({ result, error }) => {
                          if (error) {
                            message.error(
                              error.message ||
                                "There was an error while deleting the lifestyle image.",
                            );

                            return;
                          }

                          props.onDeleteComplete?.(
                            result.deletedLifestyleImage.id!,
                          );
                        });
                      },
                    });
                  }}
                />
              </Tooltip>
            </span>
          </div>
        </div>
      )}
      <img
        className="lifestyle-image-thumbnail"
        src={assetUrl}
        alt="Lifestyle"
      />
      <Tooltip title={imageName} trigger="hover">
        <h4 className="theme-image-name">{imageName}</h4>
      </Tooltip>
    </div>
  );
};

const areEqual = (
  prevProps: ThumbnailProps & ThumbnailHandlers,
  nextProps: ThumbnailProps & ThumbnailHandlers,
) => {
  const propsFromPrev = {
    id: prevProps.lifestyleImage.id,
    hoveredImageId: prevProps.hoveredImageId,
    lifestyleImageUrl: prevProps.lifestyleImage.url,
    selectedLifestyleImageUrl: prevProps.selectedLifestyleImageUrl,
  };

  const propsFromNext = {
    id: nextProps.lifestyleImage.id,
    hoveredImageId: nextProps.hoveredImageId,
    lifestyleImageUrl: nextProps.lifestyleImage.url,
    selectedLifestyleImageUrl: nextProps.selectedLifestyleImageUrl,
  };
  return isEqual(propsFromPrev, propsFromNext);
};

export default memo(Thumbnail, areEqual);
