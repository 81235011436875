import {
  useGetAutocompleteOptionsQuery,
  useGetCurrentUserQuery,
} from "redux/media/media.api";
import { useMemo } from "react";
import { SkipToken, skipToken } from "@reduxjs/toolkit/dist/query";
import { useQPBreadcrumb } from "../useQPBreadcrumb";
import { OptionData } from "rc-select/lib/interface";
import { uniqBy } from "lodash";

export const useAutocomplete = (query: string): OptionData[] => {
  const { current: albumId = "0" } = useQPBreadcrumb();
  const { data: currentUser } = useGetCurrentUserQuery();

  const payload = useMemo<WDAutoCompletePayload | SkipToken>(() => {
    if (!currentUser || query.length <= 1) {
      return skipToken;
    }

    return {
      query,
      user_id: `${currentUser.id}`,
      size: "15",
      album_id: albumId,
    };
  }, [query, albumId, currentUser]);

  const { data } = useGetAutocompleteOptionsQuery(payload);

  const options = useMemo(() => {
    return optionMapper(data?.options || []);
  }, [data]);

  return options;
};

const optionMapper = (arr: WDAutocompleteOption[]): OptionData[] => {
  return uniqBy(arr, "text").map(({ id, text }: WDAutocompleteOption) => ({
    value: text,
    label: text,
    key: id,
  }));
};
