import { Form, Input, Typography, Radio } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import { IVideoAsset } from "shared/types/adLibrary";
import { GOOGLE_ADS_ASSET_ITEMS_LIMIT } from "screens/adLibrary/constants";
import { useZodFormValidation, getErrorFromZod } from "utils/helpers.zod";
import TitleWithDescription from "shared/components/uploadAsset/TitleWithDescription";
import { videoAssetSchema } from "shared/schemas/pmax";

import styles from "../../PMaxAssetGroupList.module.scss";
import detailDrawerStyles from "../AssetGroupDetailDrawer.module.scss";
import { useCallback } from "react";
import { visibilityOptions } from "../AssetGroupDetailDrawer.utils";

const VideoAsset = ({ assets }: { assets: IVideoAsset[] }) => {
  const { selectedAssetGroup, setUpdatedAssetGroups, setEditAssetGroup } =
    usePMaxDataContext();
  const { videoAssetValidation } = useZodFormValidation({
    videoAssetValidation: {
      data: assets,
      schema: videoAssetSchema,
    },
  });

  const updateAssetGroup = (newAssets: IVideoAsset[], id?: string) => {
    if (!id) return;

    setUpdatedAssetGroups(prev => {
      const { [id]: existingAssetGroup = {} } = prev || {};
      return {
        ...(prev || {}),
        [id]: {
          ...existingAssetGroup,
          videoAssets: newAssets,
        },
      };
    });
    const updatedAssetGroup = {
      ...(selectedAssetGroup || {}),
      videoAssets: newAssets,
    };
    setEditAssetGroup(updatedAssetGroup);
  };

  const deleteRow = (index: number) => {
    const newAssets = assets.filter((_, idx) => index !== idx);
    updateAssetGroup(newAssets, selectedAssetGroup?.id);
  };

  const addRow = () => {
    const { id } = selectedAssetGroup || {};
    if (!id) return;
    const newAssets = [
      ...assets,
      {
        videoUrl: "",
        title: "",
        description: "",
        privacyStatus: "unlisted" as const,
      },
    ];
    updateAssetGroup(newAssets, selectedAssetGroup?.id);
  };

  const onChange = (newValue: string, idx: number, key: keyof IVideoAsset) => {
    const newAssets = assets.map((asset, index) => {
      return index === idx ? { ...asset, [key]: newValue } : asset;
    });
    updateAssetGroup(newAssets, selectedAssetGroup?.id);
  };

  const getSharedFormItemProps = useCallback(
    (idx: number, key: "videoUrl" | "title" | "privacyStatus") => {
      const error = getErrorFromZod(videoAssetValidation.errors, [idx, key]);
      return {
        help: error,
        validateStatus: error ? "error" : "success",
      } as const;
    },
    [videoAssetValidation.errors],
  );

  const titleMsg =
    "Must be a shared link to a video in Dropbox. It will be loaded to Youtube automatically when the asset group gets loaded to Google Ads.";

  return (
    <div className={detailDrawerStyles.section}>
      <TitleWithDescription
        title={"Videos"}
        numberLimit={5}
        minimum={0}
        info={titleMsg}
      />
      {assets.map((asset, idx) => {
        const privacyStatusDescription = visibilityOptions.find(
          o => o.value === asset?.privacyStatus,
        )?.description;
        return (
          <div
            key={`Videos-${idx}`}
            className={detailDrawerStyles.videoInfoSection}
          >
            <div
              className={detailDrawerStyles.videoInfoContainer}
              key={`input-key-${idx}`}
            >
              <DeleteOutlined
                className={styles.deleteIcon}
                onClick={() => deleteRow(idx)}
              />
              <Typography.Text className={detailDrawerStyles.videoFieldLabel}>
                {"Video URL"}
                {<span className={styles.required}>* </span>}
              </Typography.Text>
              <Form.Item
                {...getSharedFormItemProps(idx, "videoUrl")}
                className={detailDrawerStyles.videoFormItem}
              >
                <Input
                  value={asset?.videoUrl}
                  onChange={e => onChange(e.target.value, idx, "videoUrl")}
                  placeholder="Must be a Dropbox video link"
                />
              </Form.Item>

              <Typography.Text className={detailDrawerStyles.videoFieldLabel}>
                {"Title"}
                {<span className={styles.required}>* </span>}
              </Typography.Text>
              <Form.Item
                {...getSharedFormItemProps(idx, "title")}
                className={detailDrawerStyles.videoFormItem}
              >
                <Input
                  value={asset?.title}
                  onChange={e => onChange(e.target.value, idx, "title")}
                  placeholder="Title for Youtube video"
                />
              </Form.Item>
              <Typography.Text className={detailDrawerStyles.videoFieldLabel}>
                Description
              </Typography.Text>
              <Form.Item className={detailDrawerStyles.videoFormItem}>
                <Input.TextArea
                  value={asset?.description}
                  onChange={e => onChange(e.target.value, idx, "description")}
                  placeholder="Description for Youtube video"
                />
              </Form.Item>
              <Typography.Text className={detailDrawerStyles.videoFieldLabel}>
                Visibility<span className={styles.required}>* </span>
              </Typography.Text>
              <Form.Item
                className={detailDrawerStyles.videoFormItem}
                {...getSharedFormItemProps(idx, "privacyStatus")}
              >
                <Radio.Group
                  options={visibilityOptions}
                  onChange={e => onChange(e.target.value, idx, "privacyStatus")}
                  value={asset?.privacyStatus}
                  optionType="button"
                  buttonStyle="solid"
                />

                {privacyStatusDescription && (
                  <div>
                    <Typography.Text
                      type="secondary"
                      className={detailDrawerStyles.videoFieldLabel}
                    >
                      {privacyStatusDescription}
                    </Typography.Text>
                  </div>
                )}
              </Form.Item>
            </div>
          </div>
        );
      })}

      {assets?.length < GOOGLE_ADS_ASSET_ITEMS_LIMIT["videoAssets"] && (
        <div className={styles.clickableWithIcon} onClick={addRow}>
          <PlusCircleOutlined />
          <Typography.Text className={styles.clickable}>
            Add Video
          </Typography.Text>
        </div>
      )}
    </div>
  );
};

export default VideoAsset;
