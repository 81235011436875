import { Button, Drawer } from "antd";
import { memo, useState } from "react";
import { fields } from "screens/adLibrary/fields";
import AdList from "screens/adLibrary/library/AdList";
import { useAds } from "screens/adLibrary/shared/hooks/useAds";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { IAd, ToolbarButton } from "shared/types/adLibrary";
import styles from "./AdLibraryCampaignPlannerDrawer.module.scss";
import { QueryParamProvider } from "use-query-params";
import { InMemoryAdapter } from "shared/components/dataListURL/InMemoryAdapter";
import AdWizard from "screens/adLibrary/AdWizard";
import { noop } from "lodash";
import { useAdLibraryIds } from "screens/adLibrary/shared/hooks/dataListHooks";
import { CampaignPlannerInstance } from "../types";

const AdLibraryDrawer = ({
  isOpen,
  setIsOpen,
  onSelect,
  plannerAds,
  instance,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSelect: (ad: IAd[]) => void;
  plannerAds: string[];
  instance?: CampaignPlannerInstance;
}) => {
  return (
    <QueryParamProvider adapter={InMemoryAdapter}>
      <DataListURLProvider fields={fields}>
        <AdLibraryDrawerContainer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSelect={onSelect}
          plannerAds={plannerAds}
          instance={instance}
        />
      </DataListURLProvider>
    </QueryParamProvider>
  );
};

const AdLibraryDrawerContainer = ({
  isOpen,
  setIsOpen,
  onSelect,
  plannerAds,
  instance,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSelect: (ad: IAd[]) => void;
  plannerAds: string[];
  instance?: CampaignPlannerInstance;
}) => {
  const { selectedItems } = useAds({ includePlanners: true });
  const { setSelectedItemIds } = useAdLibraryIds();

  const actions: ToolbarButton = {
    Load: undefined,
    Export: undefined,
    Import: undefined,
    LoadV2: undefined,
    New: {
      disabled: false,
      onClick: () => setIsCreateWizardVisible(true),
      extraInfo: {
        text: "New Ad",
      },
    },
  };
  const [isCreateWizardVisible, setIsCreateWizardVisible] = useState(false);

  const disabledAds = plannerAds.reduce<Record<string, string>>(
    (accum, curr) => {
      accum[curr] = `This ad is already part of this ${
        instance ? "instance" : "campaign planner"
      }`;
      return accum;
    },
    {},
  );

  return (
    <Drawer
      title="Ad Library / Ads"
      width={"95%"}
      placement="right"
      bodyStyle={{ overflow: "hidden", padding: 0 }}
      closable={true}
      onClose={() => setIsOpen(false)}
      visible={isOpen}
      footerStyle={{ display: "flex", justifyContent: "flex-end" }}
      footer={
        <div className={styles.drawerButtons}>
          <Button
            onClick={() => {
              setIsOpen(false);
              setSelectedItemIds([]);
            }}
          >
            Close
          </Button>
          <Button
            type="primary"
            onClick={() => {
              onSelect(selectedItems);
              setIsOpen(false);
              setSelectedItemIds([]);
            }}
            disabled={!selectedItems.length}
          >
            Add {selectedItems.length ?? ""} Selected
          </Button>
        </div>
      }
    >
      {isCreateWizardVisible && (
        <AdWizard onClose={() => setIsCreateWizardVisible(false)} />
      )}
      <AdList
        openAdLoadDrawer={noop}
        setCreateAdWizardVisible={setIsCreateWizardVisible}
        openImportDrawer={noop}
        toolbarContentsExtra={actions}
        disabledRows={disabledAds}
      />
    </Drawer>
  );
};

export default memo(AdLibraryDrawer);
