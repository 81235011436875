import { useEffect, useMemo, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Spin, notification, message } from "antd";

import {
  ILifestyleImage,
  IAssetBuildInstance,
} from "shared/types/assetBuilder";

import GenericError from "shared/errors/GenericError";
import { IConfig } from "shared/types/configuration";
import { INewOrder } from "shared/types/newOrders";

import Thumbnail from "./imageSelectionList/Thumbnail";

import "./ImageSelectionList.scss";
import HttpClient from "services/httpClient";
import { TTemplateType } from "shared/types/designStudio";
import { CAM_ENABLED } from "shared/components/media";
import { MediaImageSelection } from "./MediaImageSelection";
import { isSameImage } from "../ImageSelection.utils";

type ImgSelectionProps = {
  lifestyleImages: ILifestyleImage[];
  fetching: boolean;
  type: TTemplateType | "";
  currOrder: INewOrder | undefined;
  instance: IAssetBuildInstance | null;
  imageType: string;
  config?: IConfig;
  savedSelectImageSearch: string;
  shouldUpdateThemeImage: boolean;
};

type ImgSelectionHandlers = {
  insertLifestyleImage?: (imageUrl: string, imageName: string) => void;
  onLifestyleImageDeleteComplete?: (id: string) => void;
  saveSelectImageSearch: (searcyBy: string) => void;
  setShouldUpdateThemeImage: (shouldUpdateThemeImage: boolean) => void;
  setNumBackgroundImages: (numBackgroundImages: number) => void;
};

type ImageSelectionListProps = ImgSelectionProps & ImgSelectionHandlers;

const withTimestamp = (url: string) => {
  const finalUrl = new URL(url);
  finalUrl.search = Date.now().toString();

  return finalUrl.toString();
};

const ImageSelectionList = (props: ImageSelectionListProps) => {
  const transformedAssetImages = useMemo(() => {
    return to2DArray(props.lifestyleImages);
  }, [props.lifestyleImages]);

  const [hoveredImageId, setHoveredImageId] = useState("");
  const [selectedImgUrl, setSelectedImageUrl] = useState("");
  const [searchBy, setSearchBy] = useState(
    props?.currOrder?.selectImageCollapseSearchInput || "",
  );
  const [didUserSearchByImageName, setDidUserSearchByImageName] =
    useState(false);

  const findMatch = (lifestyleImage: ILifestyleImage) => {
    if (props.type === "carcut" || props.type === "") {
      return searchBy
        ? lifestyleImage?.name
            ?.toLocaleLowerCase()
            .includes(searchBy.toLocaleLowerCase())
        : true;
    }
    if (props.type === "lifestyle") {
      const imageName = `${lifestyleImage.year}_${lifestyleImage.make}_${lifestyleImage.model}_${lifestyleImage.trim}`;
      return searchBy
        ? imageName?.toLocaleLowerCase().includes(searchBy.toLocaleLowerCase())
        : true;
    }
  };

  const focusedImage = useRef<HTMLImageElement>(null);
  const convertUrlToCDN = (url?: string) => {
    return url?.replace(/s3.*amazonaws.com/, "constellationagency.com") || "";
  };

  const [fetchingCustomImage, setFetchingCustomImage] = useState(false);
  const [customImageUrls, setCustomImageUrls] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    setTimeout(() => {
      if (focusedImage && focusedImage.current) {
        focusedImage.current.scrollIntoView({
          block: "center",
          inline: "start",
          behavior: "smooth",
        });
      }
    }, 500);
  }, [props.instance]);

  useEffect(() => {
    // save number of background images

    let backgroundImagesCount = 0;
    const backgroundImages = transformedAssetImages.map(row => {
      return row.filter(findMatch).map(lifestyleImage => {
        backgroundImagesCount += 1;
        return lifestyleImage;
      });
    });

    if (
      backgroundImagesCount === 1 &&
      props.shouldUpdateThemeImage &&
      !didUserSearchByImageName // did user press add new instance && has the user NOT typed anything yet?
    ) {
      for (const innerArr of backgroundImages) {
        if (innerArr.length !== 1) continue;
        // only one of the inner arrays will have length one
        const { url, name } = innerArr[0];
        const cdnUrl = convertUrlToCDN(url);
        if (!url) {
          notification.open({
            message: "This asset image has an issue.",
          });

          continue;
        }

        props.insertLifestyleImage?.(`${cdnUrl}`, name || "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformedAssetImages, searchBy]);

  useEffect(() => {
    setSearchBy(
      props.savedSelectImageSearch ? props.savedSelectImageSearch : "",
    );
  }, [props.savedSelectImageSearch]);

  useEffect(() => {
    if (
      props?.currOrder?.selectImageCollapseSearchInput &&
      !props.savedSelectImageSearch
    ) {
      setSearchBy(props?.currOrder?.selectImageCollapseSearchInput || "");
    }
  }, [
    props?.currOrder?.selectImageCollapseSearchInput,
    props.savedSelectImageSearch,
  ]);

  const lifestyleImages = useMemo(() => {
    const images: ILifestyleImage[] = [];

    for (let i = 0; i < transformedAssetImages.length; i++) {
      const row = transformedAssetImages[i];

      for (let j = 0; j < row.length; j++) {
        const lifestyleImage = row[j];

        const { make, year, model } = props.instance?.selectedOffer
          ?.offerData || { make: "", year: "", model: "" };

        const { width, height } = props.instance?.template?.artboard || {
          width: 0,
          height: 0,
        };

        const lifestyleImageTrimSplit = lifestyleImage?.trim?.split("x");
        const lifestyleImageWidth = lifestyleImageTrimSplit
          ? lifestyleImageTrimSplit[0]
          : "";
        const lifestyleImageHeight = lifestyleImageTrimSplit
          ? lifestyleImageTrimSplit[1]
          : "";

        if (
          (props.type === "carcut" || props.type === "") &&
          (lifestyleImage.oems || []).length > 0
        ) {
          const intersection = (lifestyleImage.oems || []).filter(
            oem => oem.toLowerCase() === make.toLowerCase(),
          );
          if (intersection.length <= 0) continue;
        }

        if (
          process.env.REACT_APP_AV2_CLIENT === "internal" &&
          props.type === "lifestyle" &&
          (lifestyleImage?.make?.toLowerCase() !== make.toLowerCase() || // make check
            lifestyleImage?.year !== parseInt(year) || // year check
            lifestyleImage?.model !== model || // model check
            (lifestyleImageWidth && // size check
              lifestyleImageHeight &&
              (parseInt(lifestyleImageHeight) !== height ||
                parseInt(lifestyleImageWidth) !== width)))
        )
          continue;

        images.push(lifestyleImage);
      }
    }
    const filteredImages = images.filter(findMatch);
    props.setNumBackgroundImages(filteredImages.length);
    return filteredImages;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformedAssetImages, searchBy]);

  const imageSelection = async (selectedImg: ILifestyleImage) => {
    const { url, name = "" } = selectedImg;
    if (!url) {
      notification.open({
        message: "This asset image has an issue.",
      });
      return;
    }
    const imageUrl = customImageUrls[url] || url;
    setSelectedImageUrl(imageUrl);
    const imageType = url.match(/(jpe?g|png)$/gi);
    if (
      imageType &&
      !imageUrl.includes("constellationagency.com") &&
      !imageUrl.includes("webdam") &&
      props.config
    ) {
      setFetchingCustomImage(true);
      const { url: newUrl } =
        (await uploadImage(
          url,
          props.config,
          imageType ? imageType[0] : "jpg",
        )) || {};
      const newCdnUrl = convertUrlToCDN(newUrl);

      setCustomImageUrls({
        ...customImageUrls,
        [url]: newCdnUrl,
      });
      setFetchingCustomImage(false);
      props.insertLifestyleImage?.(`${newCdnUrl}`, name);
    } else {
      props.insertLifestyleImage?.(`${withTimestamp(imageUrl)}`, name);
    }
  };

  return (
    <>
      <Input
        placeholder="Search By Asset Name"
        className="theme-image-search-by-name"
        value={searchBy}
        prefix={<SearchOutlined />}
        allowClear={true}
        onChange={e => {
          setSearchBy(e.target.value);
          props.saveSelectImageSearch(e.target.value);
          setDidUserSearchByImageName(true);
        }}
      />
      {CAM_ENABLED && (
        <MediaImageSelection
          type={props.type}
          instance={props.instance}
          imageSelection={imageSelection}
        />
      )}
      <Spin spinning={props.fetching || fetchingCustomImage} tip="Loading...">
        <ul className="image-selection-list">
          {!props.fetching && transformedAssetImages.length === 0 && (
            <li>No images</li>
          )}
          {lifestyleImages.map((lifestyleImage, innerIdx) => {
            return (
              <li key={`image-selection-row-${innerIdx}`}>
                <div
                  key={`ls-image-div-${innerIdx}`}
                  ref={
                    isSameImage(
                      selectedImgUrl || props.instance?.lifestyleImageUrl,
                      convertUrlToCDN(lifestyleImage.url),
                    )
                      ? focusedImage
                      : null
                  }
                >
                  <Thumbnail
                    key={`ls-image-thumbnail-${innerIdx}`}
                    config={props.config}
                    type={props.type}
                    lifestyleImage={lifestyleImage}
                    hoveredImageId={hoveredImageId}
                    imageType={props.imageType}
                    selectedLifestyleImageUrl={
                      selectedImgUrl || props.instance?.lifestyleImageUrl
                    }
                    setHoveredImageId={id => {
                      setHoveredImageId(id || "");
                    }}
                    convertUrlToCDN={convertUrlToCDN}
                    onSelect={async e => {
                      e.preventDefault();
                      await imageSelection(lifestyleImage);
                    }}
                    onDeleteComplete={id => {
                      props.onLifestyleImageDeleteComplete?.(id); // defined in ImageSElection.tsx
                    }}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </Spin>
    </>
  );
};

const uploadImage = async (
  url: string,
  config: IConfig,
  imageType?: string,
) => {
  const uploadCustomImageUrl = `${config.services.assetBuilder.uploadCustomImageUrl}`;
  const { result, error } = await HttpClient.post<{
    result: { lifestyleImage: ILifestyleImage };
    error: GenericError;
  }>(
    uploadCustomImageUrl,
    {
      url,
      imageType,
    },
    {
      cache: "no-cache",
    },
  );

  if (error) {
    message.error(error.message);

    return;
  }

  const { lifestyleImage: createdLifestyleImage } = result;

  return createdLifestyleImage;
};

const to2DArray = (lifestyleImages: ILifestyleImage[]) => {
  const result: ILifestyleImage[][] = [];
  let row: ILifestyleImage[] = [];
  for (const lifestyleImage of lifestyleImages) {
    row.push(lifestyleImage);

    // 3 images per row
    if (row.length === 3) {
      result.push(row);

      row = [];
    }
  }

  if (row.length > 0) {
    result.push(row);
  }

  return result;
};

export default ImageSelectionList;
