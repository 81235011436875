import { useQuery } from "react-query";
import API from "services";
import { IImageStatusResponse } from "shared/types/genAI";

const useIsImageUploaded = (args: { id: string }) => {
  const { data } = useQuery<IImageStatusResponse | null, Error>(
    ["getImageStatus", args.id],
    () => API.services.genAI.getImageStatus(args.id),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: refetchedData => {
        const shouldRefetch =
          !refetchedData ||
          refetchedData?.id !== args.id ||
          refetchedData?.upload_status !== "uploaded";

        if (shouldRefetch) {
          return 1000;
        }
        return false;
      },
    },
  );

  return data?.upload_status === "uploaded" || false;
};

export default useIsImageUploaded;
