import { useCallback, useEffect, useState } from "react";
import { Button, Col, Drawer, message, Row, Space, Tooltip } from "antd";
import { StepsConfig } from "./newFeedDrawer/StepsConfig";
import { UploadStep } from "./newFeedDrawer/UploadStep";
import { MapColStep } from "./newFeedDrawer/MapColStep";
import styles from "./NewFeedDrawer.module.scss";
import useUploadCsv from "shared/hooks/assetExporter/useUploadCsv";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import useServerConfig from "shared/hooks/assetExporter/useServerConfig";
import { SchedulingStep } from "./newFeedDrawer/SchedulingStep";
import { RcFile } from "antd/lib/upload";
import { ReviewStep } from "./newFeedDrawer/ReviewStep";
import { useCreateFeedCron } from "shared/hooks/assetExporter/useMutateFeeds";
import { STEP } from "shared/types/assetExporter";
import useFetchCurrConfig from "shared/hooks/assetExporter/useFetchCurrConfig";
import {
  SheetData,
  usePostGoogleSheet,
} from "shared/hooks/assetExporter/useFetchGoogleSheet";
import { UseMutationOptions } from "react-query";

export const NewFeedDrawer = () => {
  const { feedDrawer, isFeedDrawerOpen } = useAppSelector(
    state => state.assetExporter.sourceFeed,
  );
  const { googleSheetId, googleSheetName } = feedDrawer;
  const actions = useAdEngineActions();

  const { setNextStep, setPrevStep, setUploadedFile } = actions;
  const [customFile, setCustomFile] = useState<RcFile>();
  const isUpdate = feedDrawer.drawerMode === "UPDATE";

  const {
    data: customFilename,
    mutate: uploadCsv,
    isLoading: isUploadingCsv,
    isSuccess: isUploadSuccess,
  } = useUploadCsv();

  const {
    data: serverFilename,
    mutate: putServerConfig,
    isLoading: isPuttingConfig,
    isSuccess: isConfigSuccess,
    isError: isConfigError,
  } = useServerConfig();

  const postGoogleSheetOptions: Omit<
    UseMutationOptions<string, Error, SheetData>,
    "mutationFn"
  > = {
    onSuccess: filename => {
      setUploadedFile(filename);
      setNextStep();
    },
    onError: errorMsg => {
      message.error(errorMsg.message);
    },
  };
  const { mutate: postGoogleSheet, isLoading: isPostingGoogleSheet } =
    usePostGoogleSheet(postGoogleSheetOptions);

  const {
    mutate: createFeedCron,
    isLoading: isCreatingCron,
    isSuccess: isCronCreated,
  } = useCreateFeedCron();

  const {
    data: feedConfig,
    isLoading: isFeedConfigLoading,
    isSuccess: isFeedConfigSuccess,
  } = useFetchCurrConfig(feedDrawer.feedId, isUpdate);

  const onServerConfig = useCallback(() => {
    if (isConfigSuccess && serverFilename) {
      setUploadedFile(serverFilename);
      setNextStep();
    }
    if (isConfigError) message.error("Unable to proceed");
  }, [
    isConfigSuccess,
    setNextStep,
    setUploadedFile,
    serverFilename,
    isConfigError,
  ]);

  const onCustomConfig = useCallback(() => {
    if (isUploadSuccess && customFilename) {
      setUploadedFile(customFilename);
      setNextStep();
    }
  }, [isUploadSuccess, setNextStep, setUploadedFile, customFilename]);

  const onDrawerClose = useCallback(() => {
    if (!isFeedDrawerOpen) setCustomFile(undefined);
  }, [isFeedDrawerOpen]);

  const onImportSuccess = useCallback(() => {
    if (isCronCreated) actions.resetDrawer();
  }, [actions, isCronCreated]);

  const onFeedUpdateConfig = useCallback(() => {
    if (isFeedConfigSuccess && feedConfig) {
      actions.setFeedConfig(feedConfig);
    }
  }, [actions, feedConfig, isFeedConfigSuccess]);

  useEffect(onServerConfig, [onServerConfig]);
  useEffect(onCustomConfig, [onCustomConfig]);
  useEffect(onDrawerClose, [onDrawerClose]);
  useEffect(onImportSuccess, [onImportSuccess]);
  useEffect(onFeedUpdateConfig, [onFeedUpdateConfig]);

  const currStep = feedDrawer.step;
  const stepAction = feedDrawer.latestStep;

  const isCustomType = feedDrawer.uploadType === "custom";
  const isGoogleSheetType = feedDrawer.uploadType === "googlesheet";
  const isServerType = feedDrawer.uploadType === "server";
  const displayScheduleStep = isGoogleSheetType || isServerType;

  const onClick = async () => {
    if (stepAction === STEP.ONE) {
      if (isCustomType) {
        if (!!customFile) uploadCsv(customFile);
        else setNextStep();
      } else if (isGoogleSheetType) {
        postGoogleSheet({ sheetId: googleSheetId, sheetName: googleSheetName });
      } else {
        putServerConfig();
      }
    } else if (stepAction === STEP.TWO && isUpdate && isCustomType) {
      createFeedCron(feedDrawer);
    } else if (stepAction === STEP.THREE && isUpdate && !isCustomType) {
      createFeedCron(feedDrawer);
    } else if (stepAction === STEP.THREE && isCustomType) {
      createFeedCron(feedDrawer);
    } else if (stepAction === STEP.FOUR && !isCustomType) {
      createFeedCron(feedDrawer);
    } else {
      setNextStep();
    }
  };

  const handlePrevStep = async () => {
    if (stepAction === STEP.ONE) return;

    setPrevStep();
  };

  const isLoading =
    isUploadingCsv ||
    isPuttingConfig ||
    isCreatingCron ||
    isFeedConfigLoading ||
    isPostingGoogleSheet ||
    feedDrawer.isProcessingPreview;

  const latestStep = feedDrawer.latestStep;
  const btnTextCreate =
    latestStep < STEP.FOUR || (latestStep === STEP.FOUR && displayScheduleStep)
      ? "Next"
      : "Complete Import";

  const btnTextUpdate =
    latestStep === STEP.ONE ||
    latestStep === STEP.TWO ||
    (latestStep === STEP.THREE && displayScheduleStep)
      ? "Next"
      : "Complete Edit";

  return (
    <Drawer
      title={<strong>{isUpdate ? "Edit" : "Add New"} Feed</strong>}
      placement="right"
      visible={isFeedDrawerOpen}
      width="80%"
      footer={
        <Space className={styles.modalFooter}>
          <Button onClick={() => actions.resetDrawer()}>Cancel</Button>
          <div>
            {currStep !== STEP.ONE && (
              <Button onClick={handlePrevStep} className={styles.backButton}>
                Back
              </Button>
            )}
            {!!feedDrawer.errMsg ? (
              <Tooltip title={feedDrawer.errMsg}>
                <Button disabled={true} data-cy="feed-next-btn">
                  Next
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="primary"
                disabled={feedDrawer.isNextBtnDisabled}
                loading={isLoading}
                onClick={onClick}
                data-cy="feed-next-btn"
              >
                {isUpdate ? btnTextUpdate : btnTextCreate}
              </Button>
            )}
          </div>
        </Space>
      }
      footerStyle={{ display: "flex", justifyContent: "flex-end" }}
      onClose={() => actions.resetDrawer()}
    >
      <Row className={styles.bodyContainer}>
        <Col span={5} className={styles.borderLine}>
          <StepsConfig />
        </Col>
        <Col span={19}>
          <div className={styles.stepsContainer}>
            {isUpdate ? (
              <>
                {currStep === STEP.ONE && (
                  <UploadStep
                    setCustomFile={setCustomFile}
                    customFile={customFile}
                  />
                )}
                {currStep === STEP.TWO && displayScheduleStep && (
                  <SchedulingStep />
                )}
                {currStep === STEP.TWO && !displayScheduleStep && (
                  <ReviewStep />
                )}
                {currStep === STEP.THREE && displayScheduleStep && (
                  <ReviewStep />
                )}
              </>
            ) : (
              <>
                {currStep === STEP.ONE && (
                  <UploadStep
                    key={`new_feed_drawer_key_${isFeedDrawerOpen}`}
                    setCustomFile={setCustomFile}
                    customFile={customFile}
                  />
                )}
                {currStep === STEP.TWO && <MapColStep />}
                {currStep === STEP.THREE && displayScheduleStep && (
                  <SchedulingStep />
                )}
                {currStep === STEP.THREE && isCustomType && <ReviewStep />}
                {currStep === STEP.FOUR && displayScheduleStep && (
                  <ReviewStep />
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </Drawer>
  );
};
