import { UploadFile } from "antd/lib/upload/interface";
import { ImageAssetType, MultiInputAssetType } from "shared/types/adLibrary";
import { IFile } from "shared/types/uploadAsset";
import { z } from "zod";
import { imageRatioValidator } from "./uploadAssets";

// https://developers.google.com/google-ads/api/docs/performance-max/assets
const PMAX_MAX_ASSETS = 20;

const areFilesDuplicated = (files: UploadFile<IFile>[]) => {
  const urls = files.map(f => f.url);
  return new Set(urls).size === urls.length;
};

const duplicatedErrorCopy = "There are duplicated images";

export const landscapeImagesSchema = z
  .array(
    z
      .custom<UploadFile<IFile>>()
      .refine(
        async file => imageRatioValidator(file, 1.91),
        "Landscape Images ratio should be 1.91",
      ),
  )
  .min(1, "Please add at least one Landscape Image")
  .max(20, "A maximum of 20 Landscape Images is allowed")
  .refine(areFilesDuplicated, duplicatedErrorCopy);

export const squareImagesSchema = z
  .array(
    z
      .custom<UploadFile<IFile>>()
      .refine(
        async file => imageRatioValidator(file, 1),
        "Square Images ratio should be 1",
      ),
  )
  .min(1, "Please add at least one Square Image")
  .max(20, "A maximum of 20 Square Images is allowed")
  .refine(areFilesDuplicated, duplicatedErrorCopy);

export const portraitImagesSchema = z
  .array(
    z
      .custom<UploadFile<IFile>>()
      .refine(
        async file => imageRatioValidator(file, 0.8),
        "Protrait Images ratio should be 0.8",
      ),
  )
  .max(5, "A maximum of 5 Portrait Images is allowed")
  .refine(areFilesDuplicated, duplicatedErrorCopy);

export const landscapeLogosSchema = z
  .array(
    z
      .custom<UploadFile<IFile>>()
      .refine(
        async file => imageRatioValidator(file, 4),
        "Landscape Logos ratio should be 4",
      ),
  )
  .max(5, "A maximum of 5 Landscape Logos is allowed")
  .refine(areFilesDuplicated, duplicatedErrorCopy);

export const squareLogosSchema = z
  .array(
    z
      .custom<UploadFile<IFile>>()
      .refine(
        async file => imageRatioValidator(file, 1),
        "Square Logos ratio should be 1",
      ),
  )
  .min(1, "Please add at least one Square Logo")
  .max(5, "A maximum of 5 Square Logos is allowed")
  .refine(areFilesDuplicated, duplicatedErrorCopy);

const uploadsSchema = z
  .object({
    landscapeImages: landscapeImagesSchema,
    squareImages: squareImagesSchema,
    portraitImages: portraitImagesSchema,
    landscapeLogos: landscapeLogosSchema,
    squareLogos: squareLogosSchema,
  })
  .refine(
    uploads => Object.values(uploads).flat().length <= PMAX_MAX_ASSETS,
    `The maximum amount of images is ${PMAX_MAX_ASSETS} (sum of landscape, square, and portrait). Please remove the exceeding images.`,
  );

const noExclamationPointsAllowedRegExp = /^[^!]*$/;

export const headlinesSchema = z
  .array(
    z
      .string()
      .min(1, "Headlines cannot be empty")
      .max(30, "Please keep the Headlines under 30 characters")
      .regex(noExclamationPointsAllowedRegExp, "No exclamation points allowed")
      .trim(),
  )
  .min(3, "Please add at least three Headlines")
  .max(5, "A maximum of 5 Headlines is allowed")
  .refine(
    headlines => headlines.some(text => text.length <= 15),
    "At least one Headline must be 15 characters or less",
  );

export const longHeadlinesSchema = z
  .array(
    z
      .string()
      .min(1, "Long Headlines cannot be empty")
      .max(90, "Please keep the Long Headline under 90 characters")
      .regex(noExclamationPointsAllowedRegExp, "No exclamation points allowed")
      .trim(),
  )
  .min(1, "Please add at least 1 Long Headline")
  .max(5, "A maximum of 5 Long Headlines is allowed")
  .refine(headlines => {
    const uniqueHeadlines = new Set(headlines);
    return uniqueHeadlines.size === headlines.length;
  }, "Long Headlines must be unique");

export const descriptionsSchema = z
  .array(
    z
      .string()
      .min(1, "Descriptions cannot be empty")
      .max(90, "Please keep the Description under 90 characters")
      .trim(),
  )
  .min(2, "Please add at least 2 Descriptions")
  .max(5, "A maximum of 5 Descriptions is allowed")
  .refine(
    descriptions => descriptions.some(text => text.length <= 60),
    "At least one description must be 60 characters or less",
  );
export const videoAssetSchema = z.array(
  z.object({
    videoUrl: z
      .string()
      .min(1, "This field is required")
      .refine(
        url => /dropbox/.test(url),
        "Must be a shared link to a video in Dropbox. It will be loaded to Youtube automatically when the asset group gets loaded to Google Ads.",
      ),
    title: z.string().min(1, "This field is required"),
    privacyStatus: z.enum(["public", "unlisted", "private"], {
      required_error: "This field is required",
    }),
  }),
);

export const youtubeVideoUrlsSchema = z
  .array(z.string())
  .max(5, "A maximum of 5 URLs is allowed");

export const multiInputAssetSchema = z.object({
  headlines: headlinesSchema,
  longHeadlines: longHeadlinesSchema,
  descriptions: descriptionsSchema,
  youtubeVideoUrls: youtubeVideoUrlsSchema,
});

export const finalUrlSchema = z
  .string({
    required_error: "Final URL is required",
    invalid_type_error: "Final URL is required",
  })
  .min(1, "Final URL is required");

export const businessNameSchema = z
  .string({ required_error: "Business name is required" })
  .min(1, "Business name is required")
  .max(25, "Business name must be under 25 characters");

export const pmaxSchema = z.object({
  id: z.string(),
  multiInputAsset: multiInputAssetSchema,
  finalUrl: finalUrlSchema,
  businessName: businessNameSchema,
});

export const getMultiInputAssetSchema = (title?: MultiInputAssetType) => {
  switch (title) {
    case "Headlines":
      return headlinesSchema;
    case "Long Headlines":
      return longHeadlinesSchema;
    case "Descriptions":
      return descriptionsSchema;
    case "Youtube Video URLs":
      return youtubeVideoUrlsSchema;
    default:
      return multiInputAssetSchema;
  }
};

export const getUploadsSchema = (title?: ImageAssetType) => {
  switch (title) {
    case "Landscape Images":
      return landscapeImagesSchema;
    case "Square Images":
      return squareImagesSchema;
    case "Portrait Images":
      return portraitImagesSchema;
    case "Landscape Logos":
      return landscapeLogosSchema;
    case "Square Logos":
      return squareLogosSchema;
    default:
      return uploadsSchema;
  }
};

export const googleAdCampaignFormSchema = (validAccounts: string[]) =>
  z.object({
    name: z.string().min(1, "Please add a Campaign name"),
    startDate: z
      .custom<moment.Moment>()
      .refine(date => date?.isValid(), { message: "Please add a start date" }),
    locationTarget: z
      .string()
      .min(1, "Please add a location")
      .refine(location => validAccounts.includes(location), {
        message: "Address is missing for this account",
      }),
    radius: z
      .number({ required_error: "Please add a radius" })
      .min(1, "Enter a bigger number")
      .max(500, "Enter a smaller number"),
    radiusUnit: z.union([z.literal("mi"), z.literal("km")]),
    dailyBudget: z.preprocess(
      Number,
      z
        .number()
        .min(1, "Daily budget needs to be greater than $1.00")
        .max(9_006_999_999, "Enter a smaller number"),
    ),
  });
